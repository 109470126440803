import React, { useState, useEffect } from 'react';

export default function Component(props) {
    const [selectedAddressInner, setSelectedAddressInner] = useState(undefined);
    const { selectedAddress, editingUserAddress } = props;
    useEffect(() => {
        setSelectedAddressInner(selectedAddress);
    }, [selectedAddress]);
    return (
        <>
            {props.empty == true ? (
                <div className="col px-1 my-1 col-md-4 col-lg-3 address-wrapper text-center">
                    <div
                        className={`address-inner-wrapper ${
                            props.isForProfile === true
                                ? 'min-height-alt'
                                : 'min-height'
                        }`}
                    >
                        <div className="order-delivery__button-wrap">
                            <button
                                type="button"
                                onClick={props.onAddPress}
                                className="button button--solid order-delivery__button my-4"
                            >
                                {_e('Nové místo doručení')}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="">
                    <div className="order-info-block">
                        {props.isForProfile !== true && !props.justForShowing && (
                            <div className="order-info-block__checkbox-wrap">
                                <label className="order-info-block__checkbox">
                                    <input
                                        onChange={(e) =>
                                            props.onSelect(
                                                e,
                                                'clientAddress',
                                                props.data.id
                                            )
                                        }
                                        checked={
                                            selectedAddressInner ===
                                            props.data.id
                                                ? true
                                                : null
                                        }
                                        type="checkbox"
                                        className="order-info-block__checkbox-input"
                                    />
                                    <span className="order-info-block__checkbox-check"></span>
                                </label>
                            </div>
                        )}
                        <div className="order-info-block__title">
                            {props.data.client_name}
                        </div>
                        <div className="order-info-block__delivery">
                            {`${props.data.stat}, ${props.data.mesto} ${props.data.ulice}`}
                        </div>
                        <div className="order-info-block__phone">
                            <a
                                style={{
                                    color: '#707070',
                                }}
                                target="_blank"
                                href={`tel:${props.data.telefon}`}
                            >
                                {props.data.telefon}
                            </a>
                        </div>
                        {!props.justForShowing && (
                            <div className="order-info-block__edit-wrap">
                                <div
                                    className={`order-info-block__edit ${
                                        props?.clientAddress?.id ===
                                            props.data?.id &&
                                        props.editingClient
                                            ? 'disabled'
                                            : ''
                                    }`}
                                    onClick={() => props.onEdit(props.data)}
                                >
                                    <img
                                        src="assets/img/order/edit-icon.png"
                                        alt="edit"
                                        className="order-info-block__edit-img"
                                    />
                                    <img
                                        src="assets/img/order/edit-icon-disabled.png"
                                        alt="edit-disabled"
                                        className="order-info-block__edit-img order-info-block__edit-img--disabled"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {/*<p>{props.data.ulice}</p>*/}
                    {/*<p>{props.data.company_name}</p>*/}
                    {/*<p className="d-none">{props.data.ico}</p>*/}
                    {/*<p className="d-none">{props.data.dic}</p>*/}
                    {/*<p>{props.data.ulice}</p>*/}
                    {/*<p className="d-none">{props.data.stat}</p>*/}
                    {/*<p className="d-none">{props.data.mesto}</p>*/}
                    {/*<p className="d-none">{props.data.psc}</p>*/}
                    {/*<p>*/}
                    {/*    <a href={`tel:${props.data.telefon}`}>*/}
                    {/*        {props.data.telefon}*/}
                    {/*    </a>*/}
                    {/*</p>*/}
                    {/*<p className="d-none">*/}
                    {/*    <a*/}
                    {/*        target="_blank"*/}
                    {/*        href={`mailto:${props.data.email}`}*/}
                    {/*    >*/}
                    {/*        {props.data.email}*/}
                    {/*    </a>*/}
                    {/*</p>*/}
                    {/*<p className="d-none">{props.data.bank_code}</p>*/}
                </div>
            )}
        </>
    );
}
