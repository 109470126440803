import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FormHeader from '../components/FormHeader';
import { getAddressInfo } from '../actions/Address';
import { getProducts } from '../actions/Product';
import WithAuth from '../hocs/WithAuth';
import Products from '../components/StepThree/Products';
import Summary from '../components/StepThree/Summary';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import stepActions from '../redux/actions/Step';
import productActions from '../redux/actions/Product';
import modalActions from '../redux/actions/Modal';

function Screen(props) {
    const { orderProducts, actions, stepTwo, stepOne } = props;
    let history = useHistory();
    const [showingImages, setShowingImages] = useState(false);
    const [canContinue, setCanContinue] = useState(true);
    const [isDropShipping, setIsDropShipping] = useState(false);
    const [userAddress, setUserAddress] = useState({});
    const [products, setProducts] = useState({});
    const [clientAddress, setClientAddress] = useState({});
    const [stepOneInfo, setStepOneInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceVat, setTotalPriceVat] = useState(0);
    const [totalProductCount, setTotalProductCount] = useState(0);

    const fetchData = async () => {
        let productsInfo = orderProducts;
        if (!productsInfo || Object.keys(productsInfo).length < 1) {
            setLoading(true);
            history.push('/');
            return false;
        }
        let addressInfo = await getAddressInfo(stepOne, stepTwo);
        let products = await getProducts(productsInfo);
        console.log(products, Object.values(products));
        setProducts(Object.values(products));
        let dropShipping = stepOne?.dropShipping;
        setIsDropShipping(dropShipping);
        const userAddress = addressInfo['userAddress'];
        const clientAddress = addressInfo['clientAddress'];
        setStepOneInfo({
            deliveryMethod: addressInfo['deliveryMethod'],
            paymentMethod: addressInfo['paymentMethod'],
        });
        if (null !== clientAddress) {
            setClientAddress(clientAddress);
        }
        setUserAddress(userAddress);
        setLoading(false);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        const { showOrders } = actions;
        showOrders();

        fetchData();

        document.title = global._e('Cart products');
    }, []);
    useEffect(() => {
        fetchData();
    }, [orderProducts]);

    useEffect(() => {
        if (Object.keys(products).length > 0) {
            let total = products.reduce(
                (sum, product) => sum + product.price * product.count,
                0
            );
            let totalVat = products.reduce(
                (sum, product) => sum + product.price_vat * product.count,
                0
            );
            let totalCount = products.reduce(
                (sum, product) => sum + product.count,
                0
            );
            setTotalPrice(total);
            setTotalPriceVat(totalVat);
            setTotalProductCount(totalCount);
        }
    }, [products]);
    const toggleShowingImages = () => {
        setShowingImages(!showingImages);
    };
    const submit = async () => {
        history.push('/step_1');
    };

    // CLIENT ADDRESS
    return (
        <div className="order">
            <FormHeader
                canContinue={canContinue}
                loading={loading}
                step="0"
                title={_e('Cart products')}
            />
            {!loading && (
                <div className="order-content-3">
                    <div className="container">
                        <div className="order-cart">
                            <div className="order-cart-top">
                                <h1 className="order-cart-title">
                                    {global._e('Products')}
                                </h1>
                                <div className="order-cart-view-all-products__wrap">
                                    <div
                                        onClick={toggleShowingImages}
                                        className="order-cart-view-all-products closed"
                                    >
                                        {showingImages
                                            ? global._e(
                                                  'Скрыть картинки товаров'
                                              )
                                            : global._e(
                                                  'Показать картинки товаров'
                                              )}
                                    </div>
                                </div>
                            </div>
                            <Products showingImages={showingImages} />
                            <Summary
                                step={0}
                                paymentMethod={stepOneInfo.paymentMethod}
                                dropShipping={isDropShipping}
                                deliveryMethod={stepOneInfo.deliveryMethod}
                                totalPrice={totalPrice}
                                totalPriceVat={totalPriceVat}
                                productCount={totalProductCount}
                            />
                        </div>
                    </div>
                    <div className="order-delivery__button-wrap">
                        <button
                            type="button"
                            onClick={submit}
                            className="button button--solid order-delivery__button"
                        >
                            {_e('Pokrakovat')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
const mapStateToProps = (state) => ({
    stepOne: state.stepReducer.stepOne,
    stepTwo: state.stepReducer.stepTwo,
    stepThree: state.stepReducer.stepThree,
    orderProducts: state.orderReducer.products,
    finalData: state.stepReducer,
});

const ActionCreators = Object.assign(
    {},
    productActions,
    stepActions,
    modalActions
);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithAuth(Screen));
