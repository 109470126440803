import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import loader from '../assets/loader.gif';
import { useHistory } from 'react-router-dom';

function Component(props) {
    const { step: currentStep, title } = props;
    let history = useHistory();
    // const [width, setWidth] = useState(currentStep == 1 ? 100 : 50);
    // useEffect(() => {
    //     console.log(props);
    // }, []);

    return (
        <div className="order-top">
            <div className="container">
                <div className="order-back-link__wrap">
                    {currentStep != 0 ? (
                        <button
                            onClick={() => history.goBack()}
                            className="order-back-link"
                        >
                            <b>{_e('Zpet')}</b>
                        </button>
                    ) : null}
                </div>
                <div className="order-stages">
                    <div className="order-stages__header">
                        <span>{title}</span>
                    </div>
                    <div className="order-stages__blocks">
                        <Link
                            to="step_0"
                            className={`order-stages__block ${
                                currentStep == 0 ? 'active' : ''
                            }`}
                        >
                            <img
                                src="assets/img/order/step0.png"
                                alt="baggage"
                                className="order-stages__block-img"
                            />
                            <img
                                src="assets/img/order/step0.png"
                                alt="baggage-active"
                                className={`order-stages__block-img ${
                                    currentStep == 0
                                        ? 'order-stages__block-img--active'
                                        : 'hidden'
                                }`}
                            />
                        </Link>
                        <hr className="order-stages__line active" />
                        <Link
                            to="step_1"
                            className={`order-stages__block ${
                                currentStep == 1 ? 'active' : ''
                            }`}
                        >
                            <img
                                src="assets/img/order/step2.png"
                                alt="baggage"
                                className="order-stages__block-img"
                            />
                            <img
                                src="assets/img/order/step2.png"
                                alt="baggage-active"
                                className={`order-stages__block-img ${
                                    currentStep == 1
                                        ? 'order-stages__block-img--active'
                                        : 'hidden'
                                }`}
                            />
                        </Link>
                        <hr className="order-stages__line active" />
                        <Link
                            to="step_2"
                            className={`order-stages__block ${
                                currentStep == 2 ? 'active' : ''
                            }`}
                        >
                            <img
                                src="assets/img/order/step3.png"
                                alt="location"
                                className="dumb-icon order-stages__block-img"
                            />
                            <img
                                src="assets/img/order/step3.png"
                                alt="location-active"
                                className={`order-stages__block-img ${
                                    currentStep == 2
                                        ? ' order-stages__block-img--active'
                                        : 'hidden'
                                }`}
                            />
                        </Link>
                        <hr className="order-stages__line" />
                        <Link
                            to="step_3"
                            className={`order-stages__block ${
                                currentStep == 3 ? 'active' : ''
                            }`}
                        >
                            <img
                                src="assets/img/order/step4.png"
                                alt="cart"
                                className="order-stages__block-img"
                            />
                            <img
                                src="assets/img/order/step4.png"
                                alt="cart-active"
                                className={`order-stages__block-img ${
                                    currentStep == 3
                                        ? 'order-stages__block-img--active'
                                        : 'hidden'
                                }`}
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <img
                src={loader}
                className={`loader ${
                    (false === props.loading || undefined == props.loading) &&
                    'loader-hidden'
                }`}
            />
        </div>
    );
}

export default Component;
