const initialState = {
    stepOne: null,
    stepOneValid: false,
    stepTwo: null,
    stepTwoValid: false,
    stepThree: null,
    stepThreeValid: false,
    onStep: 0,
};

const stepReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_STEP_ONE':
            return {
                ...state,
                stepOne: action.payload,
                onStep: 1,
                stepOneValid: true,
                stepTwo: null,
                stepThree: null,
            };
        case 'SET_STEP_TWO':
            return {
                ...state,
                stepTwo: action.payload,
                onStep: 2,
                stepTwoValid: true,
            };
        case 'SET_STEP_THREE':
            return {
                ...state,
                stepThree: action.payload,
                onStep: 3,
                stepThreeValid: true,
            };
        case 'CLEAR_STEPS':
            return initialState;
        default:
            return state;
    }
};
export default stepReducer;
