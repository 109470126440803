import React, { useEffect, useState } from 'react';

const Component = (props) => {
    const { options, radioName, className, onChange, initialVal } = props;
    const [selected, setSelected] = useState(null);
    useEffect(() => {}, []);
    // className order-delivery__radio
    const select = (el) => {
        if (onChange) {
            onChange(el.val);
        }
        setSelected(el.val);
    };
    useEffect(() => {
        setSelected(initialVal);
    }, [initialVal]);
    return (
        <>
            {options.map((el, key) => {
                return (
                    <div key={key} className={`radio ${className || ''}`}>
                        <label className="radio__label">
                            <input
                                className="radio__input"
                                type="radio"
                                checked={el.val == selected ? true : false}
                                name={radioName || 'some_radio'}
                                onChange={() => select(el)}
                            />
                            <span className="radio__check"></span>
                            <span className="radio__title">{_e(el.title)}</span>
                        </label>
                        {el.description ? (
                            <div className="radio__descr">
                                {_e(el.description)}
                            </div>
                        ) : null}
                    </div>
                );
            })}
        </>
    );
};

export default Component;
