import axios from 'axios';
axios.defaults.withCredentials = true;

const setStatic = (data) => {
    return {
        type: 'GET_BASIC',
        payload: data,
    };
};

const getBasic = (locale = 'cz') => {
    return async (dispatch, getState) => {
        // if (getState().staticReducer.loaded) return;
        // await dispatch(
        //     setStatic({ loading: true, loaded: false, error: false })
        // );
        try {
            let header = await global.getHeader();
            const url = `${global.API_ADDRESS}/api/basic`;
            let res = await axios.get(url, header).catch(async (error) => {
                // await dispatch(
                //     setStatic({ loading: false, loaded: false, error: true })
                // );
                console.log(error);
                global.Alert(error.toString());
            });
            await dispatch(
                setStatic({
                    // loading: false,
                    // loaded: true,
                    // error: false,
                    ...res?.data,
                })
            );
            return res?.data || [];
        } catch (error) {
            // await dispatch(
            //     setStatic({ loading: false, loaded: false, error: true })
            // );
            global.Alert(error.toString());
            console.error(error);
        }
    };
};

export default { getBasic };
