import React, { useState, useEffect } from 'react';
import orderActions from '../../redux/actions/Order';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProducts } from '../../actions/Product';

function Component(props) {
    const { orderProducts, showingImages } = props;
    console.log({ orderProducts });
    const fetchData = async () => {
        if (!orderProducts || Object.keys(orderProducts).length < 1) {
            return false;
        }
        let products = await getProducts(orderProducts);
        setProducts(Object.values(products));
    };
    const [products, setProducts] = useState([]);
    const { setOrderExact, changeOrder } = props.actions;
    const removeProduct = (el) => {
        setOrderExact(el, 0);
    };
    useEffect(() => {
        fetchData();
    }, [orderProducts]);
    useEffect(() => {
        setShowingImagesInner(showingImages);
    }, [showingImages]);
    const [showingImagesInner, setShowingImagesInner] = useState(false);
    return (
        <div className="order-cart-products">
            {products.map((el, key) => {
                return (
                    <div
                        key={key}
                        className={`order-product ${
                            showingImagesInner ? 'step3shown' : ''
                        }`}
                    >
                        <div className="order-product__header">
                            <div className="order-product__idx">{key + 1}</div>
                            <div className="order-product__desc-img">
                                <img
                                    src={el.image_url}
                                    alt="order-product-desc-img"
                                />
                            </div>
                            <div className="order-product__title">
                                {el.title}
                                <span>{el.external_id}</span>
                            </div>
                            <div className="order-product__count products-block__count">
                                <div
                                    onClick={() => {
                                        changeOrder(el, 'minus');
                                    }}
                                    className="products-block__count-minus"
                                ></div>
                                <input
                                    type="text"
                                    className="products-block__count-input"
                                    onChange={(e) =>
                                        setOrderExact(el, e.target.value)
                                    }
                                    value={
                                        orderProducts[el.external_id]?.count ||
                                        0
                                    }
                                />
                                <div
                                    onClick={() => {
                                        changeOrder(el, 'plus');
                                    }}
                                    className="products-block__count-plus"
                                ></div>
                            </div>
                            <div className="order-product__price">
                                {/*<span>{global.nf(el.price)}</span>*/}
                                {/*{global._e('currency_alt')}*/}
                                <span className="voc_dmoc_stepthree">
                                    {global.locale === 'cz' ? (
                                        <>
                                            VOC bez DPH: {el?.voc}{' '}
                                            {global._e('currency_alt')}
                                            {/*<br />*/}
                                            {/*DMOC s DPH: {el?.dmoc_with}{' '}*/}
                                            {/*{global._e('currency_alt')}*/}
                                        </>
                                    ) : (
                                        <>
                                            VOC bez DPH: {el?.eur}{' '}
                                            {global._e('currency_alt')}
                                            {/*<br />*/}
                                            {/*DMOC s DPH: {el?.eur_dmoc}{' '}*/}
                                            {/*{global._e('currency_alt')}*/}
                                        </>
                                    )}
                                </span>
                            </div>
                            <div className="order-product__cost">
                                {global._e('Итого')}:{' '}
                                <div className="order-product__cost-price-cur">
                                    <span>
                                        {global.nf(el.price * el.count)}
                                    </span>{' '}
                                    {global._e('currency_alt')}
                                </div>
                            </div>
                            <div
                                className="order-product__delete"
                                onClick={() => removeProduct(el)}
                            >
                                <img
                                    src="assets/img/order/plus-delete.png"
                                    alt="delete 1"
                                />
                            </div>
                        </div>
                        <div className="order-product__body">
                            <div className="order-product__img">
                                <img src={el.image_url} alt="order product" />
                            </div>
                        </div>
                        <div className="order-product__footer">
                            <div className="order-product__price">
                                {/*<span>{global.nf(el.price)}</span>*/}
                                {/*{global._e('currency_alt')}*/}
                                <span className="voc_dmoc_stepthree_mobile">
                                    {global.locale === 'cz' ? (
                                        <>
                                            VOC bez DPH: {el?.voc}{' '}
                                            {global._e('currency_alt')}
                                            {/*<br />*/}
                                            {/*DMOC s DPH: {el?.dmoc_with}{' '}*/}
                                            {/*{global._e('currency_alt')}*/}
                                        </>
                                    ) : (
                                        <>
                                            VOC bez DPH: {el?.eur}{' '}
                                            {global._e('currency_alt')}
                                            {/*<br />*/}
                                            {/*DMOC s DPH: {el?.eur_dmoc}{' '}*/}
                                            {/*{global._e('currency_alt')}*/}
                                        </>
                                    )}
                                </span>
                            </div>
                            <div className="order-product__count products-block__count">
                                <div
                                    onClick={() => {
                                        changeOrder(el, 'minus');
                                    }}
                                    className="products-block__count-minus"
                                ></div>
                                <input
                                    type="text"
                                    className="products-block__count-input disabled_input"
                                    onChange={(e) =>
                                        setOrderExact(el, e.target.value)
                                    }
                                    value={
                                        orderProducts[el.external_id]?.count ||
                                        0
                                    }
                                />
                                <div
                                    onClick={() => {
                                        changeOrder(el, 'plus');
                                    }}
                                    className="products-block__count-plus"
                                ></div>
                            </div>
                            <div className="order-product__cost">
                                {global._e('Итого')}:{' '}
                                <div className="order-product__cost-price-cur">
                                    <span>
                                        {global.nf(el.price * el.count)}
                                    </span>{' '}
                                    {global._e('currency_alt')}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

const ActionCreators = Object.assign({}, orderActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});
const mapStateToProps = (state) => ({
    orderProducts: state.orderReducer.products,
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
