import React, { useEffect, useState } from 'react';
import FormHeader from '../components/FormHeader';
import FormFooter from '../components/FormFooter';
import Address from '../components/Address';
import AddressForm from '../components/AddressForm';
import ClientAddressForm from '../components/ClientAddressForm';
import ClientAddress from '../components/ClientAddress';
import WithAddresses from '../hocs/WithAddresses';
import WithAuth from '../hocs/WithAuth';
import step from '../redux/reducers/step';

function Screen(props) {
    const { stepTwo, stepOne } = props;
    const [canFinish, setCanFinish] = useState(false);
    const [samovivoz, setSamovivoz] = useState(false);
    useEffect(() => {
        let clientAddressSet = false;
        const clientAddress = stepTwo?.clientAddress;
        if (clientAddress) {
            if (isNaN(clientAddress)) {
                if (Object.keys(clientAddress)?.length > 0) {
                    clientAddressSet = true;
                }
            } else {
                if (clientAddress > 1) {
                    clientAddressSet = true;
                }
            }
        }
        if (
            (clientAddressSet || samovivoz) &&
            stepTwo?.userAddress &&
            stepTwo?.userAddress > 1
        ) {
            setCanFinish(true);
        } else {
            setCanFinish(false);
        }
    }, [stepTwo]);
    useEffect(() => {
        if (
            stepOne?.deliveryMethod?.ms_id ===
            '37424b33-ed6a-11eb-0a80-05ec0020a989'
        ) {
            setSamovivoz(true);
        }
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (props?.userAddresses?.length < 1 && props.userAddressesReady) {
            props.onAddPress();
        }
    }, [props.userAddressesReady]);

    return (
        <div>
            {props.canView && (
                <div className="order">
                    <FormHeader
                        loading={props.loading}
                        step="2"
                        title={_e('Dodací údaje')}
                        canContinue={props.canContinue}
                    />

                    <div className="order-content-2">
                        <div className="container">
                            <div className="order-location">
                                {!props.loading && (
                                    <form
                                        action=""
                                        className="order-location__form"
                                    >
                                        <div className="order-info-section">
                                            <div className="order-info-section__header">
                                                {global._e('Fakturační údaje')}
                                            </div>
                                            <div className="order-info-blocks">
                                                {props.userAddresses.map(
                                                    (val, key) => {
                                                        return (
                                                            <Address
                                                                selectedAddress={
                                                                    props.selectedAddress
                                                                }
                                                                onSelect={
                                                                    props.selectAddress
                                                                }
                                                                onEdit={
                                                                    props.onEditAddress
                                                                }
                                                                editingUserAddress={
                                                                    props.editingUserAddress
                                                                }
                                                                key={key}
                                                                data={val}
                                                                userAddressEdit={
                                                                    props.userAddressEdit
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </div>
                                            {props?.userAddresses?.length <
                                            1 ? (
                                                <Address
                                                    onAddPress={
                                                        props.onAddPress
                                                    }
                                                    empty={true}
                                                    onPress={props.onAddPress}
                                                />
                                            ) : null}
                                        </div>
                                        {props.addingUserAddress && (
                                            <AddressForm
                                                action="add"
                                                onSubmit={props.onDoneAdd}
                                                isDropShipping={
                                                    props.isDropShipping
                                                }
                                            />
                                        )}
                                        {props.editingUserAddress && (
                                            <AddressForm
                                                action="edit"
                                                onSubmit={props.onDoneEdit}
                                                userAddressEdit={
                                                    props.userAddressEdit
                                                }
                                            />
                                        )}
                                        <hr />
                                        {samovivoz ? null : (
                                            <h2 className="my-4 center-xs">
                                                {global._e('Dodací údaje')}
                                            </h2>
                                        )}
                                        {!samovivoz ? (
                                            props.isDropShipping ? (
                                                <ClientAddressForm
                                                    action="add"
                                                    clientAddress={
                                                        props.clientAddress
                                                    }
                                                    onSubmit={props.submit}
                                                    onValidated={
                                                        props.clientValidated
                                                    }
                                                    isDropShipping={true}
                                                />
                                            ) : (
                                                <>
                                                    <div className="row d-flex mb-4">
                                                        {props.clientAddresses.map(
                                                            (val, key) => {
                                                                return (
                                                                    <ClientAddress
                                                                        selectedAddress={
                                                                            props.selectedClientAddress
                                                                        }
                                                                        onSelect={
                                                                            props.selectAddress
                                                                        }
                                                                        onEdit={
                                                                            props.onEditClientAddress
                                                                        }
                                                                        editingClient={
                                                                            props.editingClient
                                                                        }
                                                                        key={
                                                                            key
                                                                        }
                                                                        data={
                                                                            val
                                                                        }
                                                                        clientAddress={
                                                                            props.clientAddress
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                        <ClientAddress
                                                            onAddPress={
                                                                props.onAddClientPress
                                                            }
                                                            empty={true}
                                                        />
                                                    </div>
                                                    {props.addingClient && (
                                                        <ClientAddressForm
                                                            action="add"
                                                            onValidated={false}
                                                            onSubmit={
                                                                props.onDoneAddClient
                                                            }
                                                        />
                                                    )}
                                                    {props.editingClient && (
                                                        <ClientAddressForm
                                                            action="edit"
                                                            onValidated={false}
                                                            onSubmit={
                                                                props.onDoneEditClient
                                                            }
                                                            clientAddress={
                                                                props.clientAddress
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )
                                        ) : null}
                                        <div className="order-delivery__button-wrap">
                                            <button
                                                disabled={!canFinish}
                                                type="button"
                                                onClick={props.submit}
                                                className={`button button--solid order-delivery__button ${
                                                    canFinish ? '' : 'disabled'
                                                }`}
                                            >
                                                {_e('Pokrakovat')}
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default WithAddresses(WithAuth(Screen), true);
