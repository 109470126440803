import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import { connect } from 'react-redux';
import orderActions from '../../redux/actions/Order';
import productActions from '../../redux/actions/Product';
import { bindActionCreators } from 'redux';
import NoProducts from './NoProducts';

const Screen = (props) => {
    const {
        loading,
        orderProducts,
        ordersShown,
        menuAllProductsShown,
        totalPrices,
        basicInfo,
    } = props;
    const [totals, setTotals] = useState({});
    const { changeOrder, setOrderExact, hideOrders } = props.actions;
    const [active, setActive] = useState([]);
    const [mobileSliderActive, setMobileSliderActive] = useState(false);
    const [activeTab, setActiveTab] = useState({});
    const [products, setProducts] = useState(null);
    const [productGroupsNotEmpty, setProductGroupsNotEmpty] = useState([]);
    const selectMobileSliderActive = (active) => {
        setMobileSliderActive(active);
        if (!active) {
            global.productColorSliderDestroyAll();
        } else {
            global.productColorSliderInitAll();
        }
    };
    const makeActiveTab = (id, pos) => {
        const isMobile = global.isMobile();
        if (activeTab[id] && activeTab[id] === pos && isMobile) {
            const newActiveTab = Object.assign({}, activeTab);
            delete newActiveTab[id];
            setActiveTab(newActiveTab);
        } else {
            setActiveTab({ ...activeTab, [id]: pos });
        }
        if (isMobile && mobileSliderActive) {
            global.productColorSliderInit(`#color-slider-${id}`);
        }
    };
    useEffect(() => {
        if (products === null) return;
        if (menuAllProductsShown) {
            const res = [];
            const resTab = {};
            products.map((el) => {
                resTab[el[0]['external_id']] = 'first';
                res.push(el[0]['external_id']);
            });
            setActiveTab(resTab);
            setActive(res);
        } else {
            setActive([]);
        }
    }, [menuAllProductsShown]);
    const makeActive = (id, pos) => {
        // console.log(active, id);
        if (active.includes(id)) {
            setActive(active.filter((e) => e !== id));
        } else {
            makeActiveTab(id, 'first');
            setActive([...active, id]);
        }
    };
    useEffect(() => {
        if (ordersShown === true) {
            const newProducts = {};
            for (let key in orderProducts) {
                const product = orderProducts[key];
                if (undefined == newProducts[product.group_title]) {
                    newProducts[product.group_title] = [];
                }
                newProducts[product.group_title].push(product);
            }
            const newProductsArray = Object.values(newProducts);
            if (newProductsArray.length) {
                setProducts(newProductsArray);
            }
        } else {
            setProducts(props.products);
        }
    }, [ordersShown]);
    useEffect(() => {
        hideOrders();
        setProducts(props.products);
        setActive([]);
    }, [props.products]);
    useEffect(() => {
        const newTotals = {};
        Object.values(orderProducts).map((el, key) => {
            let this_id = el['external_id'];
            let totalSk = el['totalSk'];
            let totalCz = el['totalCz'];
            if (!products || !products.length) return;
            products.map((prodGroup) => {
                // console.log({ prodGroup });
                let check = prodGroup.some(
                    (subProd) => subProd.external_id === this_id
                );
                if (check === true) {
                    if (undefined !== newTotals[prodGroup[0]['external_id']]) {
                        newTotals[prodGroup[0]['external_id']] = {
                            sk:
                                newTotals[prodGroup[0]['external_id']]['sk'] +
                                totalSk,
                            cz:
                                newTotals[prodGroup[0]['external_id']]['cz'] +
                                totalCz,
                        };
                    } else {
                        newTotals[prodGroup[0]['external_id']] = {
                            sk: totalSk,
                            cz: totalCz,
                        };
                    }
                }
            });
            // console.log(el, key);
        });
        const productGroupTemp = [];
        for (let external_id in orderProducts) {
            // console.log(external_id);
            for (let key in products) {
                // console.log(
                //     products[key]?.filter((el) => el.external_id == external_id)
                //         ?.length
                // );
                if (
                    products[key]?.filter((el) => el.external_id == external_id)
                        ?.length
                ) {
                    productGroupTemp.push(products[key][0]?.group_id);
                    // console.log(products[key], external_id, products[0]);
                }
            }
        }
        setProductGroupsNotEmpty(productGroupTemp);
        setTotals(newTotals);
    }, [orderProducts, products]);
    return (
        <div className="products-body__right">
            <div className="products-blocks">
                {products && products.length ? (
                    products.map((el, key) => {
                        const description = el?.[0]?.['description']?.replace(
                            'href',
                            'target="_blank" href'
                        );
                        return (
                            ((ordersShown &&
                                productGroupsNotEmpty.includes(
                                    el[0]['group_id']
                                )) ||
                                !ordersShown) && (
                                <div
                                    key={key}
                                    className={`products-block products-block--mod-1 ${
                                        active.includes(el[0]['external_id'])
                                            ? // ||
                                              // expandedProducts.includes(
                                              //     el[0]['external_id']
                                              // )
                                              'opened'
                                            : 'closed'
                                    }`}
                                >
                                    <div className="products-block-header">
                                        <div className="products-block__icon">
                                            <img
                                                src={el[0]['image_url']}
                                                alt="product"
                                            />
                                        </div>
                                        <h4 className="products-block__title">
                                            {el[0]['group_title']}
                                        </h4>
                                        <div
                                            className="products-block__more"
                                            onClick={() =>
                                                makeActive(
                                                    el[0]['external_id'],
                                                    'first'
                                                )
                                            }
                                        >
                                            {active.includes(
                                                el[0]['external_id']
                                            )
                                                ? _e('Show less')
                                                : _e('Show more')}
                                        </div>
                                        <div className="products-block__price">
                                            <span>
                                                {global.locale === 'cz'
                                                    ? global.nf(el[0]['voc'])
                                                    : global.nf(el[0]['eur'])}
                                            </span>{' '}
                                            {global._e('currency_alt')}/
                                            {_e('шт')}
                                        </div>
                                        <div className="products-block__status">
                                            {_e('Ordered')}
                                        </div>
                                        <div className="products-block__cost">
                                            {_e('Total')}:{' '}
                                            <div className="products-block__cost-price-cur">
                                                <span>
                                                    {global.nf(
                                                        totals?.[
                                                            el[0]['external_id']
                                                        ]?.[global.locale]
                                                    ) ?? 0}
                                                </span>{' '}
                                                {global._e('currency_alt')}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`products-block-body ${
                                            active.includes(
                                                el[0]['external_id']
                                            )
                                                ? 'shown'
                                                : 'hidden'
                                        }`}
                                    >
                                        <ul
                                            className="products-block-tabs"
                                            data-tabgroup="group1"
                                        >
                                            <li
                                                className={`products-block-tabs__item ${
                                                    activeTab[
                                                        el[0]['external_id']
                                                    ] &&
                                                    activeTab[
                                                        el[0]['external_id']
                                                    ] == 'first'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                data-target="#color1"
                                                onClick={() =>
                                                    makeActiveTab(
                                                        el[0].external_id,
                                                        'first'
                                                    )
                                                }
                                            >
                                                {_e('Product variations')}
                                            </li>
                                            {el[0]['description']?.length ? (
                                                <li
                                                    className={`products-block-tabs__item ${
                                                        activeTab[
                                                            el[0]['external_id']
                                                        ] &&
                                                        activeTab[
                                                            el[0]['external_id']
                                                        ] == 'last'
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    data-target="#descr1"
                                                    onClick={() =>
                                                        makeActiveTab(
                                                            el[0].external_id,
                                                            'last'
                                                        )
                                                    }
                                                >
                                                    {_e('Description')}
                                                </li>
                                            ) : null}
                                        </ul>
                                        <div
                                            className="products-block-tabs-content"
                                            id="group1"
                                        >
                                            <div
                                                className={`products-block-tabs-content__pane ${
                                                    active.includes(
                                                        el[0]['external_id']
                                                    ) &&
                                                    activeTab[
                                                        el[0]['external_id']
                                                    ] &&
                                                    activeTab[
                                                        el[0]['external_id']
                                                    ] == 'first'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                id="color1"
                                            >
                                                <div
                                                    onClick={() => {
                                                        makeActiveTab(
                                                            el[0].external_id,
                                                            'first'
                                                        );
                                                    }}
                                                    className={`products-block-tabs-content__pane-tab-mobile pointer ${
                                                        active.includes(
                                                            el[0]['external_id']
                                                        ) &&
                                                        activeTab[
                                                            el[0]['external_id']
                                                        ] &&
                                                        activeTab[
                                                            el[0]['external_id']
                                                        ] == 'first'
                                                            ? 'mobile-opened'
                                                            : 'mobile-closed'
                                                    }`}
                                                    data-color-slider={`#color-slider-${el[0]['external_id']}`}
                                                >
                                                    {_e('Colors')}
                                                </div>
                                                {active.includes(
                                                    el[0]['external_id']
                                                ) &&
                                                activeTab[
                                                    el[0]['external_id']
                                                ] &&
                                                activeTab[
                                                    el[0]['external_id']
                                                ] == 'first' ? (
                                                    <div
                                                        className={`products-block-tabs-content__pane-content products-block-color ${
                                                            mobileSliderActive
                                                                ? 'slider-active'
                                                                : 'slider-destroy'
                                                        } `}
                                                        id={`color-slider-${el[0]['external_id']}`}
                                                    >
                                                        <div className="products-block-color__wrapper">
                                                            {el.map(
                                                                (
                                                                    subEl,
                                                                    subKey
                                                                ) => {
                                                                    return (
                                                                        ((orderProducts[
                                                                            subEl
                                                                                .external_id
                                                                        ]
                                                                            ?.count >
                                                                            0 &&
                                                                            ordersShown) ||
                                                                            !ordersShown) && (
                                                                            <div
                                                                                key={
                                                                                    subEl[
                                                                                        'external_id'
                                                                                    ]
                                                                                }
                                                                                className="products-block-color__item"
                                                                            >
                                                                                <div className="products-block-card">
                                                                                    <div className="products-block-card__title">
                                                                                        {
                                                                                            subEl[
                                                                                                'title'
                                                                                            ]
                                                                                        }
                                                                                        <span>
                                                                                            {
                                                                                                subEl[
                                                                                                    'external_id'
                                                                                                ]
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="products-block-card__img">
                                                                                        <picture>
                                                                                            <source
                                                                                                media="(min-width:1366px)"
                                                                                                srcSet={
                                                                                                    subEl[
                                                                                                        'image_url'
                                                                                                    ]
                                                                                                }
                                                                                                type="image/png"
                                                                                            />
                                                                                            <source
                                                                                                media="(max-width:1365px)"
                                                                                                srcSet={
                                                                                                    subEl[
                                                                                                        'image_url'
                                                                                                    ]
                                                                                                }
                                                                                                type="image/png"
                                                                                            />
                                                                                            <img
                                                                                                data-gallery={
                                                                                                    subEl[
                                                                                                        'gallery'
                                                                                                    ]
                                                                                                }
                                                                                                src={
                                                                                                    subEl[
                                                                                                        'image_url'
                                                                                                    ]
                                                                                                }
                                                                                                alt="card 1"
                                                                                            />
                                                                                        </picture>
                                                                                    </div>
                                                                                    <div className="product-availability-info-container">
                                                                                        {`${global._e(
                                                                                            'В складе'
                                                                                        )} ${
                                                                                            subEl?.availability_count >
                                                                                            5
                                                                                                ? '> 5'
                                                                                                : subEl?.availability_count
                                                                                        }`}
                                                                                    </div>
                                                                                    <div className="products-block__count products-block-card__count">
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                changeOrder(
                                                                                                    subEl,
                                                                                                    'minus'
                                                                                                );
                                                                                            }}
                                                                                            className="products-block__count-minus"
                                                                                        ></div>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="products-block__count-input"
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setOrderExact(
                                                                                                    subEl,
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                )
                                                                                            }
                                                                                            value={
                                                                                                orderProducts[
                                                                                                    subEl
                                                                                                        .external_id
                                                                                                ]
                                                                                                    ?.count ||
                                                                                                0
                                                                                            }
                                                                                        />
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                changeOrder(
                                                                                                    subEl,
                                                                                                    'plus'
                                                                                                );
                                                                                            }}
                                                                                            className="products-block__count-plus"
                                                                                        ></div>
                                                                                    </div>
                                                                                    <div className="product-price-info-container">
                                                                                        {global.locale ===
                                                                                        'cz' ? (
                                                                                            <>
                                                                                                VOC
                                                                                                bez
                                                                                                DPH:{' '}
                                                                                                {
                                                                                                    subEl?.voc
                                                                                                }
                                                                                                <br />
                                                                                                DMOC
                                                                                                s
                                                                                                DPH:{' '}
                                                                                                {
                                                                                                    subEl?.dmoc_with
                                                                                                }
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                VOC
                                                                                                bez
                                                                                                DPH:{' '}
                                                                                                {
                                                                                                    subEl?.eur
                                                                                                }
                                                                                                <br />
                                                                                                DMOC
                                                                                                s
                                                                                                DPH:{' '}
                                                                                                {
                                                                                                    subEl?.eur_dmoc
                                                                                                }
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                        <div className="products-block-color__nav products-block-color__nav--prev"></div>
                                                        <div className="products-block-color__nav products-block-color__nav--next"></div>
                                                        <div className="pointer view-all-colors__wrap">
                                                            <a
                                                                onClick={() =>
                                                                    selectMobileSliderActive(
                                                                        !mobileSliderActive
                                                                    )
                                                                }
                                                                className="view-all-colors"
                                                            >
                                                                {mobileSliderActive
                                                                    ? _e(
                                                                          'Show all colors'
                                                                      )
                                                                    : _e(
                                                                          'Hide all colors'
                                                                      )}
                                                            </a>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            {description?.length ? (
                                                <div
                                                    className={`products-block-tabs-content__pane ${
                                                        active.includes(
                                                            el[0]['external_id']
                                                        ) &&
                                                        activeTab[
                                                            el[0]['external_id']
                                                        ] &&
                                                        activeTab[
                                                            el[0]['external_id']
                                                        ] == 'last'
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    id="descr1"
                                                >
                                                    <div
                                                        onClick={() =>
                                                            makeActiveTab(
                                                                el[0]
                                                                    .external_id,
                                                                'last'
                                                            )
                                                        }
                                                        className={`products-block-tabs-content__pane-tab-mobile pointer ${
                                                            active.includes(
                                                                el[0][
                                                                    'external_id'
                                                                ]
                                                            ) &&
                                                            activeTab[
                                                                el[0][
                                                                    'external_id'
                                                                ]
                                                            ] &&
                                                            activeTab[
                                                                el[0][
                                                                    'external_id'
                                                                ]
                                                            ] === 'last'
                                                                ? 'mobile-opened'
                                                                : 'mobile-closed'
                                                        }`}
                                                    >
                                                        {_e('Description')}
                                                    </div>
                                                    {active.includes(
                                                        el[0]['external_id']
                                                    ) &&
                                                    activeTab[
                                                        el[0]['external_id']
                                                    ] &&
                                                    activeTab[
                                                        el[0]['external_id']
                                                    ] === 'last' ? (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: description,
                                                            }}
                                                            className="products-block-tabs-content__pane-content products-block-descr"
                                                        ></div>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="products-block-footer">
                                        <div
                                            onClick={() =>
                                                makeActive(
                                                    el[0]['external_id'],
                                                    'first'
                                                )
                                            }
                                            className="products-block__more products-block__more--mobile"
                                        >
                                            {_e('More')}
                                        </div>
                                    </div>
                                </div>
                            )
                        );
                    })
                ) : loading ? (
                    <Loader />
                ) : (
                    <NoProducts />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    products: state.productReducer.products,
    loading: state.productReducer.loading,
    orderProducts: state.orderReducer.products,
    ordersShown: state.productReducer.ordersShown,
    menuAllProductsShown: state.productReducer.menuAllProductsShown,
    basicInfo: state.basicReducer,
    totalPrices: state.orderReducer.totalPrices,
});

const ActionCreators = Object.assign({}, orderActions, productActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Screen);
