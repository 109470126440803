import React, { useState } from 'react';
import Loader from '../Loader';
import productActions from '../../redux/actions/Product';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function Screen(props) {
    const { getProducts } = props.actions;
    const { cats, loading } = props;
    const [activeCat, setActiveCat] = useState(null);
    const [activeParentCat, setActiveParentCat] = useState(null);
    const toggleActive = (id) => {
        if (activeParentCat === id) {
            setActiveParentCat(null);
        } else {
            setActiveParentCat(id);
        }
    };
    const selectActiveCat = (id) => {
        setActiveCat(id);
        getProducts(id);
    };
    return (
        <div className="products-body__left">
            <ul className="products-menu">
                {cats?.length ? (
                    cats.map((el, key) => {
                        return null !== el.title ? (
                            <li
                                key={key}
                                className={`products-menu__item ${
                                    activeParentCat === el.id
                                        ? 'opened'
                                        : 'closed'
                                }`}
                            >
                                <div
                                    onClick={() => toggleActive(el.id)}
                                    className="products-menu__header"
                                >
                                    <div className="products-menu__icons">
                                        <span className="products-menu__icon plus">
                                            +
                                        </span>
                                        <span className="products-menu__icon minus">
                                            -
                                        </span>
                                    </div>
                                    <div className="products-menu__title">
                                        {el['title']}
                                    </div>
                                </div>
                                {activeParentCat === el.id ? (
                                    <ul className="products-submenu">
                                        {el.cats.map((sub, subKey) => {
                                            return (
                                                <li
                                                    key={subKey}
                                                    className="products-submenu__item"
                                                >
                                                    <span
                                                        onClick={() =>
                                                            selectActiveCat(
                                                                sub.id
                                                            )
                                                        }
                                                        className={`products-submenu__link ${
                                                            sub.id === activeCat
                                                                ? 'active'
                                                                : ''
                                                        }`}
                                                    >
                                                        {sub['title']}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : null}
                            </li>
                        ) : null;
                    })
                ) : (
                    <Loader />
                )}
            </ul>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cats: state.productReducer.cats,
    loading: state.productReducer.loading,
});

const ActionCreators = Object.assign({}, productActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
