import axios from 'axios';

axios.defaults.withCredentials = true;

export const getClientAddresses = async () => {
    let header = await global.getHeader();
    let res = await axios
        .get(`${global.API_ADDRESS}/api/getClientAddresses`, header)
        .catch(global.handleError);
    return res.data;
};

export const getUserAddresses = async () => {
    let header = await global.getHeader();
    let res = await axios
        .get(`${global.API_ADDRESS}/api/getAddresses`, header)
        .catch(global.handleError);
    return res.data;
};

export const getDropShippingAddresses = async () => {
    let header = await global.getHeader();
    let res = await axios
        .get(`${global.API_ADDRESS}/api/getDropShippingAddresses`, header)
        .catch(global.handleError);
    return res.data;
};

export const getAddresses = async (is_dropshipping = false) => {
    if (is_dropshipping) {
        return await getDropShippingAddresses();
    } else {
        return await getUserAddresses();
    }
};

export const updateUserAddress = async (data) => {
    let header = await global.getHeader();
    let res = await axios
        .post(`${global.API_ADDRESS}/api/updateAddress`, data, header)
        .catch(global.handleError);
    return res.data;
};

export const createUserAddress = async (data) => {
    let header = await global.getHeader();
    let res = await axios
        .post(`${global.API_ADDRESS}/api/createAddress`, data, header)
        .catch(global.handleError);
    return res.data;
};
export const getUserDefaultAddressFields = async (data) => {
    let header = await global.getHeader();
    let res = await axios
        .get(`${global.API_ADDRESS}/api/getUserDefaultAddressFields`, header)
        .catch(global.handleError);
    return res.data;
};

export const getAddressInfo = async (stepOne, stepTwo) => {
    let header = await global.getHeader();
    const data = Object.assign({}, stepTwo);
    data['deliveryMethod'] = stepOne?.['deliveryMethod'];
    data['paymentMethod'] = stepOne?.['paymentMethod'];
    let res = await axios
        .post(`${global.API_ADDRESS}/api/getAddressInfo`, data, header)
        .catch(global.handleError);
    return res.data;
};

export const validateAddress = (data) => {
    let errors = [];
    data['is_dropshipping'] = Boolean(data['is_dropshipping']);
    if (false !== data['is_dropshipping'] && true !== data['is_dropshipping']) {
        errors.push('is_dropshipping');
    }
    if (!global.validateField(data['company_name'], 5)) {
        errors.push('company_name');
    }
    if (!global.validateField(data['ico'])) {
        errors.push('ico');
    }
    if (!global.validateField(data['dic'])) {
        errors.push('dic');
    }
    if (!global.validateBooleanField(data['dic_check'])) {
        errors.push('dic_check');
    }
    if (!global.validateField(data['ulice'], 3)) {
        errors.push('ulice');
    }
    if (!global.validateField(data['stat'])) {
        errors.push('stat');
    }
    if (!global.validateField(data['mesto'], 3)) {
        errors.push('mesto');
    }
    if (!global.validateField(data['psc'])) {
        errors.push('psc');
    }
    if (!global.validatePhoneField(data['telefon'])) {
        errors.push('telefon');
    }
    if (!global.validateEmailField(data['email'])) {
        errors.push('email');
    }
    if (!global.validateField(data['bank_code'])) {
        errors.push('bank_code');
    }
    if (errors.length > 0) {
        return errors;
    }
    return true;
};
