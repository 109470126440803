import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import { connect } from 'react-redux';
import productActions from '../../redux/actions/Product';
import { bindActionCreators } from 'redux';

function Screen(props) {
    const { getProducts } = props.actions;
    const { cats, loading } = props;
    const [initialCatSet, setInitialCatSet] = useState(false);
    const [activeCat, setActiveCat] = useState(null);
    const [activeParentCat, setActiveParentCat] = useState(null);
    const [activeParentCatTitle, setActiveParentCatTitle] = useState(null);
    const [open, setOpen] = useState(false);
    const selectActiveParentCat = (id, title) => {
        if (activeParentCat === id) {
            setActiveParentCat(null);
        } else {
            setActiveParentCat(id);
        }
        setActiveParentCatTitle(title);
    };
    const selectActiveCat = (id) => {
        setActiveCat(id);
        getProducts(id);
        setOpen(false);
    };
    useEffect(() => {
        if (!initialCatSet && cats?.length) {
            selectActiveParentCat(cats[0].id, cats[0].title);
            setInitialCatSet(true);
        }
    }, [cats]);
    return (
        <div className="products-menu-mobile-wrap">
            <div className="products-menu-mobile__caption">
                {_e('Category')}
            </div>
            <div className="products-menu-mobile__container">
                <div
                    onClick={() => setOpen(!open)}
                    className={`pointer products-menu-mobile__curr ${
                        open ? 'opened' : 'closed'
                    }`}
                >
                    {activeParentCatTitle ?? _e('Loading...')}
                </div>
                {open ? (
                    <ul className="products-menu-mobile">
                        {cats?.length ? (
                            cats.map((el) => {
                                return (
                                    <li
                                        key={el['id']}
                                        className="products-menu-mobile__item"
                                    >
                                        <div
                                            onClick={() =>
                                                selectActiveParentCat(
                                                    el['id'],
                                                    el['title']
                                                )
                                            }
                                            className="products-menu-mobile__header"
                                        >
                                            {el['title']}
                                        </div>
                                        <ul
                                            className={`products-submenu-mobile ${
                                                activeParentCat === el['id']
                                                    ? 'shown'
                                                    : 'hidden'
                                            }`}
                                        >
                                            {el['cats'].map((subVal) => {
                                                return (
                                                    <li
                                                        key={subVal['id']}
                                                        onClick={() =>
                                                            selectActiveCat(
                                                                subVal['id']
                                                            )
                                                        }
                                                        className="products-submenu-mobile__item"
                                                    >
                                                        <a
                                                            href="#"
                                                            className={`products-submenu-mobile__link ${
                                                                activeCat ===
                                                                subVal['id']
                                                                    ? 'active'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {subVal['title']}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                );
                            })
                        ) : (
                            <Loader />
                        )}
                    </ul>
                ) : null}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cats: state.productReducer.cats,
    loading: state.productReducer.loading,
});

const ActionCreators = Object.assign({}, productActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
