import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

function Component(props) {
    const {
        totalPrice,
        totalPriceVat,
        productCount,
        deliveryMethod,
        dropShipping,
        paymentMethod,
        deliveryPrice,
        step,
    } = props;
    return (
        <div className="order-cart-result">
            <div className="order-cart-result-top">
                <div className="order-cart-result-top__count order-product__count products-block__count">
                    <span className="order-cart-result-top__count-text">
                        {global._e('Заказано')}:
                    </span>
                    <input
                        type="text"
                        disabled={true}
                        className="products-block__count-input disabled_input"
                        placeholder={productCount}
                    />
                    <span className="order-cart-result-top__count-text">
                        {global._e('товаров')}
                    </span>
                </div>
                <div className="order-cart-result-top__cost order-cart-result__cost">
                    {global._e('Mezisoučet bez DPH')}:{' '}
                    <span>{global.nf(totalPrice)}</span>{' '}
                    {global._e('currency_alt')}
                </div>
            </div>
            <div className="order-cart-result-center">
                <div className="order-cart-result-center__cost order-cart-result__cost">
                    {global._e('Přepravné a balné  bez DPH')}:{' '}
                    <span>{global.nf(deliveryPrice)}</span>{' '}
                    {global._e('currency_alt')}
                </div>
                <div className="order-cart-result-center__cost order-cart-result__cost">
                    {global._e('Celkem k úhradě bez DPH')}:{' '}
                    <span>{global.nf(totalPrice + deliveryPrice)}</span>{' '}
                    {global._e('currency_alt')}
                </div>
                <div className="order-cart-result-center__cost order-cart-result__cost">
                    {global._e('DPH')}:{' '}
                    <span>{global.nf(totalPriceVat - totalPrice)}</span>{' '}
                    {global._e('currency_alt')}
                </div>
                <div className="order-cart-result-center__cost order-cart-result__cost bold">
                    {global._e('Celkem s DPH')}:{' '}
                    <span>
                        {global.nf(
                            totalPriceVat < 1
                                ? totalPrice + deliveryPrice
                                : totalPriceVat + deliveryPrice
                        )}
                    </span>{' '}
                    {global._e('currency_alt')}
                </div>
            </div>
            <div className="order-cart-result-bottom order-cart-result-bottom-custom">
                <div className="order-cart-result-bottom__price-delivery">
                    {global._e('Дропшиппинг')}:{' '}
                    <span>
                        {dropShipping ? global._e('Yes') : global._e('No')}
                    </span>{' '}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cartFull: state.orderReducer.cartFull,
    basicInfo: state.basicReducer,
    products: state.productReducer.products,
    orderedProducts: state.orderReducer.products,
    totalCount: state.orderReducer.totalCount,
    totalPrices: state.orderReducer.totalPrices,
    deliveryPrice: state.orderReducer.deliveryPrice,
});

export default connect(mapStateToProps)(Component);
