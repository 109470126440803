const initialState = {
    authenticated: false,
    user: null,
    token: null,
    userId: null,
    loading: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGOUT':
            return {
                authenticated: false,
                user: null,
                token: null,
                userId: null,
                loading: false,
            };
        case 'GET_USER':
            return {
                ...state,
                authenticated: action.payload?.authenticated ?? false,
                user: action.payload.user ?? null,
                token: action.payload?.user?.token ?? null,
                userId: action.payload?.user?.id ?? null,
                loading: action.payload?.loading ?? false,
            };
        default:
            return state;
    }
};
export default authReducer;
