import React, { useState, useEffect } from 'react';
import Address from '../Address';
import ClientAddress from '../ClientAddress';

function Component(props) {
    const { userAddress, clientAddress } = props;
    return (
        <>
            <div className="order-info-section">
                <div className="order-info-section__header">
                    {global._e('Fakturační údaje')}
                </div>
                <div className="order-info-blocks">
                    <Address data={userAddress} justForShowing={true} />
                </div>
            </div>
            {Object.keys(clientAddress).length > 0 && (
                <div className="order-info-section">
                    <div className="order-info-section__header">
                        {global._e('Dodací údaje')}
                    </div>
                    <div className="order-info-blocks">
                        <ClientAddress
                            data={clientAddress}
                            justForShowing={true}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default Component;
