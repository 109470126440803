const initialState = {};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CLEAR_CART':
            return {
                ...state,
            };
        default:
            return state;
    }
};
export default profileReducer;
