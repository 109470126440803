const initialState = {
    products: {},
    cartFull: false,
    totalPrices: {},
    deliveryPrice: 0,
    totalCount: 0,
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ORDER_PRODUCTS':
            return {
                ...state,
                products: action.payload.products,
                totalPrices: action.payload.totalPrices,
                totalCount: action.payload.totalCount,
                deliveryPrice: action.payload.deliveryPrice,
            };
        case 'SET_CART_FULL_STATE':
            return {
                ...state,
                cartFull: action.payload,
            };
        case 'CLEAR_ORDER_STATE':
            return initialState;
        // case 'DECREMENT_PRODUCT':
        //     return {
        //         products: action.payload.product,
        //     };
        default:
            return state;
    }
};
export default orderReducer;
