import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAddresses, getClientAddresses } from '../actions/Address';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import stepActions from '../redux/actions/Step';
import profileActions from '../redux/actions/Profile';
import authActions from '../redux/actions/Auth';

export default function withAddresses(WrappedComponent, isStep_2 = true) {
    const Addresses = (props) => {
        const { stepTwo, stepOne, actions, profileDropShipping } = props;
        let history = useHistory();
        const [canView, setCanView] = useState(false);
        const [canContinue, setCanContinue] = useState(false);
        const [isDropShipping, setIsDropShipping] = useState(false);
        const [selectedAddress, setSelectedAddress] = useState(null);
        const [selectedClientAddress, setSelectedClientAddress] = useState(
            null
        );
        const [userAddresses, setUserAddresses] = useState([]);
        const [userAddressesReady, setUserAddressesReady] = useState(false);
        const [clientAddresses, setClientAddresses] = useState([]);
        const [loading, setLoading] = useState(true);
        const [userAddressEdit, setUserAddressEdit] = useState({});
        const [addingUserAddress, setAddingUserAddress] = useState(false);
        const [editingUserAddress, setEditingUserAddress] = useState(false);
        // client
        const [clientAddress, setClientAddress] = useState(false);
        const [addingClient, setAddingClient] = useState(false);
        const [editingClient, setEditingClient] = useState(false);
        // client
        useEffect(() => {
            fetchData();
        }, [profileDropShipping]);
        async function fetchData() {
            let dropShipping;
            if (!isStep_2) {
                //is profile addresses
                dropShipping = profileDropShipping;
                setIsDropShipping(profileDropShipping);
            } else {
                dropShipping = stepOne?.dropShipping ?? false;
                setIsDropShipping(stepOne?.dropShipping ?? false);
            }
            const userAddresses = await getAddresses(dropShipping);
            const clientAddresses = await getClientAddresses();
            setUserAddresses(userAddresses);
            setUserAddressesReady(true);
            setClientAddresses(clientAddresses);
            setLoading(false);
        }
        useEffect(() => {
            async function handleStepTwo() {
                if (isStep_2) {
                    // alert('step2');
                    let canView = await actions.canStepTwo();
                    if (!canView) {
                        history.push('step_1');
                        return false;
                    }
                    setCanView(canView);
                    if (stepTwo?.clientAddress) {
                        setClientAddress(stepTwo?.clientAddress);
                        setSelectedClientAddress(stepTwo?.clientAddress);
                    }
                    console.log(+stepTwo?.userAddress);
                    if (+stepTwo?.userAddress > 0) {
                        setSelectedAddress(stepTwo?.userAddress);
                    }

                    let canContinue = await actions.canStepThree();
                    setCanContinue(canContinue);
                } else {
                    setCanView(true);
                }
                fetchData();
            }
            handleStepTwo();

            document.title = global._e('Dodací údaje');
        }, []);

        const submit = () => {
            if (canContinue) {
                history.push('step_3');
                return false;
            }
        };

        const selectAddress = (e, type, id) => {
            if (e.target?.checked === true) {
                if (type === 'address') {
                    actions.setStepTwo({ userAddress: id });
                    setSelectedAddress(id);
                } else {
                    actions.setStepTwo({ clientAddress: id });
                    setSelectedClientAddress(id);
                }
                let canContinue = actions.canStepThree();
                setCanContinue(canContinue);
            }
        };

        // USER ADDRESS
        const onAddPress = () => {
            setAddingUserAddress(true);
            setEditingUserAddress(false);
            setEditingClient(false);
            setAddingClient(false);
        };
        const onEditAddress = (data) => {
            setAddingUserAddress(false);
            setEditingUserAddress(true);
            setEditingClient(false);
            setAddingClient(false);
            setUserAddressEdit(data);
        };
        const updateAddressData = async (type = 'editing') => {
            const userAddresses = await getAddresses(isDropShipping);
            setUserAddresses(userAddresses);
            setUserAddressesReady(true);
            if (type == 'adding') {
                selectAddress(
                    'address',
                    userAddresses[userAddresses.length - 1]['id']
                );
            }
        };
        const onDoneEdit = async () => {
            setEditingUserAddress(false);
            updateAddressData();
            selectAddress('address', userAddressEdit.id);
        };
        const onDoneAdd = async () => {
            setAddingUserAddress(false);
            updateAddressData('adding');
        };

        // USER ADDRESS

        // CLIENT ADDRESS
        const onDoneEditClient = async () => {
            setEditingClient(false);
            updateClientAddressData();
            selectAddress('client', clientAddress.id);
        };
        const onDoneAddClient = async () => {
            setAddingClient(false);
            updateClientAddressData('adding');
        };
        const onAddClientPress = () => {
            setAddingUserAddress(false);
            setEditingUserAddress(false);
            setEditingClient(false);
            setAddingClient(true);
        };
        const onEditClientAddress = (data) => {
            setAddingUserAddress(false);
            setEditingUserAddress(false);
            setEditingClient(true);
            setAddingClient(false);
            setClientAddress(data);
        };
        const updateClientAddressData = async (type = 'editing') => {
            const clientAddresses = await getClientAddresses();
            setClientAddresses(clientAddresses);
            if (type == 'adding') {
                selectAddress(
                    'client',
                    clientAddresses[clientAddresses.length - 1]['id']
                );
            }
        };

        const clientValidated = async (data) => {
            actions.setStepTwo({ clientAddress: data });
            setClientAddress(data);
            let canContinue = actions.canStepThree();
            setCanContinue(canContinue);
        };

        return (
            <WrappedComponent
                stepTwo={stepTwo}
                selectAddress={selectAddress}
                submit={submit}
                onAddPress={onAddPress}
                onDoneEditClient={onDoneEditClient}
                onDoneAdd={onDoneAdd}
                onDoneEdit={onDoneEdit}
                updateAddressData={updateAddressData}
                onEditAddress={onEditAddress}
                clientValidated={clientValidated}
                updateClientAddressData={updateClientAddressData}
                onEditClientAddress={onEditClientAddress}
                onAddClientPress={onAddClientPress}
                addingClient={addingClient}
                editingClient={editingClient}
                onDoneAddClient={onDoneAddClient}
                canView={canView}
                userAddressesReady={userAddressesReady}
                canContinue={canContinue}
                isDropShipping={isDropShipping}
                selectedAddress={selectedAddress}
                selectedClientAddress={selectedClientAddress}
                userAddresses={userAddresses}
                clientAddresses={clientAddresses}
                clientAddress={clientAddress}
                loading={loading}
                userAddressEdit={userAddressEdit}
                addingUserAddress={addingUserAddress}
                editingUserAddress={editingUserAddress}
                {...props}
            />
        );
    };
    return connect(mapStateToProps, mapDispatchToProps)(Addresses);
}

const mapStateToProps = (state) => ({
    stepTwo: state.stepReducer.stepTwo,
    stepOne: state.stepReducer.stepOne,
    profileDropShipping: state.profileReducer.dropShipping,
});

const ActionCreators = Object.assign(
    {},
    stepActions,
    profileActions,
    authActions
);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});
