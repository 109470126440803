// import axios from 'axios';

// export const addOrder = async ( data ) => {
//   let res = await axios.post( `${global.API_ADDRESS}/api/addOrder` , { data }).catch(( error ) => {
//   	global.Alert( error.toString() )
//   });
//   return res.data;
// }

export const getPaymentMethods = async () => {
    let response = await fetch(`${global.API_ADDRESS}/api/getPaymentMethods`);
    let resp = await response.json();
    return resp;
};

export const getDeliveryMethods = async () => {
    let response = await fetch(`${global.API_ADDRESS}/api/getDeliveryMethods`);
    let resp = await response.json();
    return resp;
};
