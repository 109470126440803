window.jQuery = require('jquery');

jQuery(document).ready(function () {
    (function ($) {
        // SELECT
        setTimeout(function () {
            $('.gallery-close-icon').on('click', function () {
                console.log('close');
                $('.gallery-mask').hide();
            });
            $('.gallery-next-icon').on('click', function () {
                console.log('cloc');
                var imgNow = $('.gallery-img-wrapper img.active');
                var imgNext = imgNow.next('img');
                console.log(imgNext.length);
                if (!imgNext.length) return;
                imgNow.removeClass('active');
                imgNext.addClass('active');
            });
            $('.gallery-prev-icon').on('click', function () {
                console.log('next');
                var imgNow = $('.gallery-img-wrapper img.active');
                var imgNext = imgNow.prev('img');
                console.log(imgNext.length);
                if (!imgNext.length) return;
                // console.log(imgNow, imgNext);
                imgNow.removeClass('active');
                imgNext.addClass('active');
            });
            $(document).on('click', '[data-gallery]', function () {
                console.log('ata');
                var gallery = $(this).data('gallery');
                var galleryArr = gallery.split(',');
                console.log(galleryArr);
                $('.gallery-img-wrapper').html('');
                if (!Array.isArray(galleryArr) || galleryArr.length < 1) return;
                $('.gallery-mask').show();
                for (var key in galleryArr) {
                    var src = galleryArr[key];
                    var htmlImg = '';
                    if (key == 0) {
                        htmlImg = "<img class='active' src='" + src + "' />";
                    } else {
                        htmlImg = "<img src='" + src + "' />";
                    }
                    $('.gallery-img-wrapper').append(htmlImg);
                }
            });
        }, 3000);
        $(document).on('click', '.select__curr.closed', function (e) {
            e.preventDefault();
            $(this).removeClass('closed').addClass('opened');
            $(this).siblings('.select__list').show();
        });

        $(document).on('click', '.select__curr.opened', function (e) {
            e.preventDefault();
            $(this).removeClass('opened').addClass('closed');
            $(this).siblings('.select__list').hide();
        });

        $(document).on('click', '.select__item', function (e) {
            // e.preventDefault();
            // let value = $(this).data('value');
            // let text = $(this).html();
            // let input = $(this).parents('.select').find('.select__value');
            let curr = $(this).parents('.select').find('.select__curr');
            $(this).parents('.select__list').hide();
            // $(input).val(value);
            $(curr).removeClass('opened').addClass('closed');
        });
        // SELECT
        // $(document).on('click', '.js-menu_toggle.closed', function (e) {
        //   e.preventDefault();
        //   $('.btn-hamburger-wrap').toggleClass('btn-hamburger-wrap-opened');
        //   $(this).removeClass('closed').addClass('opened');

        //   $('.navigation').addClass('opened');

        // });

        // $(document).on('click', '.js-menu_toggle.opened', function (e) {
        //   e.preventDefault();
        //   $('.btn-hamburger-wrap').toggleClass('btn-hamburger-wrap-opened');
        //   $(this).removeClass('opened').addClass('closed');

        //   $('.navigation').removeClass('opened');

        // });

        // $(document).on('click', '.select__curr.closed', function (e) {
        //   e.preventDefault();
        //   $(this).removeClass('closed').addClass('opened');
        //   $(this).siblings('.select__list').show();
        // });

        // $(document).on('click', '.select__curr.opened', function (e) {
        //   e.preventDefault();
        //   $(this).removeClass('opened').addClass('closed');
        //   $(this).siblings('.select__list').hide();
        // });

        // $(document).on('click', '.select__item', function (e) {
        //   e.preventDefault();
        //   let value = $(this).data('value');
        //   let text = $(this).html();
        //   let input = $(this).parents('.select').find('.select__value');
        //   let curr = $(this).parents('.select').find('.select__curr');
        //   $(this).parents('.select__list').hide();
        //   $(input).val(value);
        //   $(curr).html(text).removeClass('opened').addClass('closed');
        // });

        // $(document).on('click', '.products-menu-mobile__curr.closed', function (e) {
        //   e.preventDefault();
        //   $(this).removeClass('closed').addClass('opened');
        //   $(this).siblings('.products-menu-mobile').show();

        // });

        // $(document).on('click', '.products-menu-mobile__curr.opened', function (e) {
        //   e.preventDefault();
        //   $(this).removeClass('opened').addClass('closed');
        //   $(this).siblings('.products-menu-mobile').hide();

        // });

        // $(document).on('click', '.products-menu-mobile__header.closed', function (e) {
        //   e.preventDefault();
        //   $(this).removeClass('closed').addClass('opened');
        //   $(this).siblings('.products-submenu-mobile').show();

        // });

        // $(document).on('click', '.products-menu-mobile__header.opened', function (e) {
        //   e.preventDefault();
        //   $(this).removeClass('opened').addClass('closed');
        //   $(this).siblings('.products-submenu-mobile').hide();

        // });

        // $(document).on('click', '.products-menu__item.closed .products-menu__header', function (e) {
        //   e.preventDefault();
        //   $(this).siblings('.products-submenu').stop();
        //   $(this).parent('.products-menu__item').removeClass('closed').addClass('opened');
        //   $(this).siblings('.products-submenu').slideDown();
        // });
        // $(document).on('click', '.products-menu__item.opened .products-menu__header', function (e) {
        //   e.preventDefault();
        //   $(this).siblings('.products-submenu').stop();
        //   $(this).parent('.products-menu__item').removeClass('opened').addClass('closed');
        //   $(this).siblings('.products-submenu').slideUp();
        // });

        // $(document).on('click', '.products-block.closed .products-block__more', function (e) {
        //   e.preventDefault();
        //   let productsBlock = $(this).parents('.products-block');
        //   productsBlock.find('.products-block__more').text('Свернуть');
        //   productsBlock.find('.products-block-header').siblings('.products-block-body').stop();
        //   productsBlock.removeClass('closed').addClass('opened');
        //   productsBlock.find('.products-block-header').siblings('.products-block-body').slideDown();
        // });
        // $(document).on('click', '.products-block.opened .products-block__more', function (e) {
        //   e.preventDefault();
        //   let productsBlock = $(this).parents('.products-block');
        //   productsBlock.find('.products-block__more').text('Подробнее');
        //   productsBlock.find('.products-block-header').siblings('.products-block-body').stop();
        //   productsBlock.removeClass('opened').addClass('closed');
        //   productsBlock.find('.products-block-header').siblings('.products-block-body').slideUp();
        // });

        // $(document).on('click', '.products-block-tabs-content__pane-tab-mobile.mobile-closed', function (e) {
        //   e.preventDefault();
        //   $(this).removeClass('mobile-closed').addClass('mobile-opened');
        //   $(this).siblings('.products-block-tabs-content__pane-content').stop();
        //   $(this).siblings('.products-block-tabs-content__pane-content').slideDown();
        //   let sliderID = $(this).data('color-slider');
        //   if (sliderID) {
        //     productColorSliderInit(sliderID);
        //   }
        // });
        // $(document).on('click', '.products-block-tabs-content__pane-tab-mobile.mobile-opened', function (e) {
        //   e.preventDefault();
        //   $(this).removeClass('mobile-opened').addClass('mobile-closed');
        //   $(this).siblings('.products-block-tabs-content__pane-content').stop();
        //   $(this).siblings('.products-block-tabs-content__pane-content').slideUp();
        //   let sliderID = $(this).data('color-slider');
        //   if (sliderID) {
        //     productColorSliderDestroy(sliderID);
        //   }
        // });

        // $(document).on('click', '.view-all-colors', function (e) {
        //   let slider = $(this).parents('.products-block-color');
        //   let sliderID = '#' + $(slider).attr('id');
        //   if ($(slider).hasClass('slider-active')) {
        //     $(this).text('Скрыть все расцветки');
        //     $(slider).removeClass('slider-active').addClass('slider-destroy');
        //     productColorSliderDestroy(sliderID);
        //   } else {
        //     $(this).text('Показать все расцветки');
        //     $(slider).addClass('slider-active').removeClass('slider-destroy');
        //     $(slider).css('display', 'block');
        //     productColorSliderInit(sliderID);
        //   }
        // });

        // $('.products-block-tabs .products-block-tabs__item').click(function(e){
        //   e.preventDefault();
        //     var $this = $(this),
        //         tabgroup = '#'+$this.parents('.products-block-tabs').data('tabgroup'),
        //         others = $this.siblings(),
        //         target = $this.data('target');
        //     others.removeClass('active');
        //     $this.addClass('active');
        //     $(tabgroup).children('.products-block-tabs-content__pane').removeClass('active');
        //     $(target).addClass('active');

        // });

        // $(document).on('click', '.products-header__view-all-products.closed', function (e) {
        //   e.preventDefault();
        //   $(this).text('Свернуть все товары');
        //   $('.products-block-body').stop();
        //   $('.products-block').removeClass('closed').addClass('opened');
        //   $('.products-block__more').text('Свернуть');
        //   $(this).removeClass('closed').addClass('opened');
        //   $('.products-block-body').slideDown('slow', 'linear');
        //   let tabs = $('.products-block-tabs-content__pane-tab-mobile[data-color-slider]');
        //   $(tabs).removeClass('mobile-closed').addClass('mobile-opened');
        //   $(tabs).siblings('.products-block-color').show();
        //   $(tabs).siblings('.products-block-color').each(function( index ) {
        //     productColorSliderInit('#' + $(this).attr('id'));
        //   });
        // });
        // $(document).on('click', '.products-header__view-all-products.opened', function (e) {
        //   e.preventDefault();
        //   $(this).text('Раскрыть все товары');
        //   $('.products-block-body').stop();
        //   $('.products-block').removeClass('opened').addClass('closed');
        //   $('.products-block__more').text('Подробнее');
        //   $(this).removeClass('opened').addClass('closed');
        //   $('.products-block-body').slideUp('slow', 'linear');
        //   let tabs = $('.products-block-tabs-content__pane-tab-mobile[data-color-slider]');
        //   $(tabs).removeClass('mobile-opened').addClass('mobile-closed');
        // });

        // /* order-cart-product */

        // $(document).on('click', '.order-cart-view-all-products.closed', function (e) {
        //   e.preventDefault();
        //   $(this).text('Скрыть картинки товаров');
        //   $('.order-product').removeClass('closed').addClass('opened');
        //   $(this).removeClass('closed').addClass('opened');
        //   if ($(window).width() >= 1366) {
        //     $('.order-product__desc-img').show();
        //   } else {
        //     $('.order-product__body').stop();
        //     $('.order-product__body').slideDown();
        //   }
        // });
        // $(document).on('click', '.order-cart-view-all-products.opened', function (e) {
        //   e.preventDefault();
        //   $(this).text('Показать картинки товаров');
        //   $('.order-product').removeClass('opened').addClass('closed');
        //   $(this).removeClass('opened').addClass('closed');
        //   if ($(window).width() >= 1366) {
        //     $('.order-product__desc-img').hide();
        //   } else {
        //     $('.order-product__body').stop();
        //     $('.order-product__body').slideUp();
        //   }
        // });

        // $(document).on('click', '.products-header__view-order-products.closed', function (e) {
        //   e.preventDefault();
        //   $(this).text('Показать все товары');
        //   $('.products-block:not(.products-block--ordered)').hide();
        //   $(this).removeClass('closed').addClass('opened');
        // });
        // $(document).on('click', '.products-header__view-order-products.opened', function (e) {
        //   e.preventDefault();
        //   $(this).text('Показать заказанные товары');
        //   $('.products-block').show();
        //   $(this).removeClass('opened').addClass('closed');
        // });

        // /* orders-user */

        // $(document).on('click', '.user-order.closed .user-order__more', function (e) {
        //   e.preventDefault();
        //   let userOrder = $(this).parents('.user-order');
        //   userOrder.find('.user-order__header').siblings('.user-order__body').stop();
        //   userOrder.removeClass('closed').addClass('opened');
        //   userOrder.find('.user-order__header').siblings('.user-order__body').slideDown();
        // });
        // $(document).on('click', '.user-order.opened .user-order__more', function (e) {
        //   e.preventDefault();
        //   let userOrder = $(this).parents('.user-order');
        //   userOrder.find('.user-order__header').siblings('.user-order__body').stop();
        //   userOrder.removeClass('opened').addClass('closed');
        //   userOrder.find('.user-order__header').siblings('.user-order__body').slideUp();
        // });

        // $('.modal-contacts__toggle').on('click', function(e) {
        //   e.preventDefault();
        //   $('.modal-contacts').toggleClass('modal-contacts__is-visible');
        // });

        $(window).on('scroll', function () {
            stickyOrderBottom();
        });
        global.jQueryHandleStickyOrderFooter = stickyOrderBottom;

        stickyOrderBottom();

        function stickyOrderBottom() {
            if (!$('.products-footer').length) return;
            let bottomScroll = +$(window).scrollTop() + +$(window).height();
            let containerHeight = +$(
                '.products-footer .container'
            ).outerHeight();
            let offsetBottom =
                +$('.products-footer').offset().top + containerHeight;
            if (bottomScroll >= offsetBottom) {
                $('.products-footer').removeClass('products-footer--fixed');
                $('.products-footer').css('padding-top', '');
            } else {
                $('.products-footer').addClass('products-footer--fixed');
                $('.products-footer').css(
                    'padding-top',
                    containerHeight + 'px'
                );
            }
        }

        // let productColorSliders = new Map();
        // function productColorSliderInit(sliderID) {
        //   if (!productColorSliders.has(sliderID)) {
        //     productColorSliders.set(sliderID, new Swiper(sliderID, {
        //       wrapperClass: 'products-block-color__wrapper',
        //       slideClass: 'products-block-color__item',
        //       slidesPerView: 1,
        //       navigation: {
        //       nextEl: '.products-block-color__nav--next',
        //       prevEl: '.products-block-color__nav--prev',
        //     },
        //     breakpoints: {
        //       600: {
        //         slidesPerView: 3,
        //       },
        //       450: {
        //         slidesPerView: 2,
        //       },
        //       0: {
        //         slidesPerView: 1,
        //       },
        //     }
        //     }));
        //   }
        // }
        // function productColorSliderDestroy (sliderID) {
        //   if (productColorSliders.has(sliderID)) {
        //     productColorSliders.get(sliderID).destroy();
        //     productColorSliders.delete(sliderID);
        //   }
        // }

        // $(window).on('resize', function () {
        //   let windowWidth = $(this).innerWidth();

        //   if (windowWidth >= 768) {
        //     for (let sliderID of productColorSliders.keys()) {
        //       productColorSliderDestroy(sliderID);
        //     }
        //   }
        // });

        // $('.auth-form-country .select__item').on('click', function() {
        //   let code = $(this).data('value');
        //   let input = $('.auth-form-country .auth-form-block__input');
        //   switch (code) {
        //     case 'CZ':
        //       $(input).attr('placeholder', '+(420) ___-___-___');
        //       break;

        //     case 'RU':
        //       $(input).attr('placeholder', '+7 (999) 999-99-99');
        //       break;
        //   }
        // });
        var swiperUrl = 'https://unpkg.com/swiper/swiper-bundle.min.js';
        $.getScript(swiperUrl, function (data, textStatus, jqxhr) {
            var productColorSliders = new Map();
            var productColorSliderInit = function (sliderID) {
                setTimeout(() => {
                    productColorSliders.set(
                        sliderID,
                        new Swiper(sliderID, {
                            wrapperClass: 'products-block-color__wrapper',
                            slideClass: 'products-block-color__item',
                            slidesPerView: 1,
                            navigation: {
                                nextEl: '.products-block-color__nav--next',
                                prevEl: '.products-block-color__nav--prev',
                            },
                            breakpoints: {
                                600: {
                                    slidesPerView: 3,
                                },
                                450: {
                                    slidesPerView: 2,
                                },
                                0: {
                                    slidesPerView: 1,
                                },
                            },
                        })
                    );
                }, 500);
                // }
            };
            var productColorSliderDestroy = function (sliderID) {
                setTimeout(() => {
                    if (productColorSliders.has(sliderID)) {
                        productColorSliders.get(sliderID).destroy();
                        productColorSliders.delete(sliderID);
                    }
                }, 500);
            };
            var productColorSliderDestroyAll = function () {
                setTimeout(() => {
                    $('[data-color-slider]').each(function () {
                        let sliderID = $(this).data('colorSlider');
                        if (productColorSliders.get(sliderID)) {
                            productColorSliders.get(sliderID).destroy();
                            productColorSliders.delete(sliderID);
                        }
                    });
                }, 500);
            };
            var productColorSliderInitAll = function () {
                setTimeout(() => {
                    $('[data-color-slider]').each(function () {
                        let sliderID = $(this).data('colorSlider');

                        productColorSliders.set(
                            sliderID,
                            new Swiper(sliderID, {
                                wrapperClass: 'products-block-color__wrapper',
                                slideClass: 'products-block-color__item',
                                slidesPerView: 1,
                                navigation: {
                                    nextEl: '.products-block-color__nav--next',
                                    prevEl: '.products-block-color__nav--prev',
                                },
                                breakpoints: {
                                    600: {
                                        slidesPerView: 3,
                                    },
                                    450: {
                                        slidesPerView: 2,
                                    },
                                    0: {
                                        slidesPerView: 1,
                                    },
                                },
                            })
                        );
                    });
                }, 500);
            };
            global.productColorSliderInitAll = productColorSliderInitAll;
            global.productColorSliderDestroyAll = productColorSliderDestroyAll;
            global.productColorSliderDestroy = productColorSliderDestroy;
            global.productColorSliderInit = productColorSliderInit;
        });

        var handleHamburger = function () {
            $(document).on('click', '.js-menu_toggle.closed', function () {
                // e.preventDefault();
                // $(this).toggleClass('closed');
                $('.btn-hamburger-wrap').toggleClass(
                    'btn-hamburger-wrap-opened'
                );
                $(this).removeClass('closed').addClass('opened');

                $('.navigation').addClass('opened');
            });
            var closeHamburger = function (that) {
                $('.btn-hamburger-wrap').toggleClass(
                    'btn-hamburger-wrap-opened'
                );
                that.removeClass('opened').addClass('closed');
                $('.navigation').removeClass('opened');
            };

            $(document).on('click', '.navigation__item', function () {
                closeHamburger(
                    $(this).closest('.container').find('.js-menu_toggle')
                );
            });
            $(document).on('click', '.js-menu_toggle.opened', function () {
                // e.preventDefault();
                closeHamburger($(this));
            });
        };

        handleHamburger();
        //
        // $(window).on('resize', function () {
        //     let windowWidth = $(this).innerWidth();
        //
        //     if (windowWidth >= 768) {
        //         for (let sliderID of productColorSliders.keys()) {
        //             productColorSliderDestroy(sliderID);
        //         }
        //     }
        // });
    })(jQuery);
});
