import React, { useState, useEffect } from 'react';
import {
    Link,
    // useHistory
} from 'react-router-dom';
import { getOrders, getOrder } from '../actions/Order';
import Loader from '../components/Loader';
// let offset = 0;
import WithAuth from '../hocs/WithAuth';
import { connect } from 'react-redux';

const OrderComponent = React.memo((props) => {
    const { el, user } = props;
    console.log(user);
    const [order, setOrder] = useState([]);
    const [more, setMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const getMore = async () => {
        if (more === true) {
            setMore(false);
            return;
        }
        setMore(true);
        if (!order.length) {
            setLoading(true);
            let order = await getOrder(el.order.id);
            // console.log(order);
            setOrder(order);
            setLoading(false);
        }
    };
    return (
        <div className={`user-order ${more ? 'opened' : 'closed'}`}>
            <div className="user-order__header mb-2">
                <div className="user-order__id">
                    <span>{global._e('ID')}</span> {el.order.id}
                </div>
                <div className="user-order__date">{el.order.created}</div>
                <div className="user-order__name">{` ${el.order?.name} `}</div>
                <div className="user-order__state">{` ${el.order?.state} `}</div>
                <div className="user-order__more" onClick={getMore}>
                    {more === true ? global._e('Hide') : global._e('More')}
                </div>
            </div>
            <div className={`user-order__body ${more ? 'shown' : 'hidden'}`}>
                <div className="user-order-table">
                    <div className="user-order-table__header">
                        <div className="user-order-table__header-col user-order-table__header-article">
                            {global._e('Kód produktu')}
                        </div>
                        <div className="user-order-table__header-col user-order-table__header-name">
                            {global._e('Položka')}
                        </div>
                        <div className="user-order-table__header-col user-order-table__header-voc">
                            {global._e('VOC bez DPH')}
                        </div>
                        <div className="user-order-table__header-col user-order-table__header-count">
                            {global._e('Množství')}
                        </div>
                        <div className="user-order-table__header-col user-order-table__header-cost">
                            {global._e('Částka')}
                        </div>
                        <div className="user-order-table__header-col user-order-table__header-stav">
                            {global._e('Stav')}
                        </div>
                    </div>
                    {loading ? (
                        <div className="user-order-table__rows">
                            <div className="user-order-table__row">
                                <Loader />
                            </div>
                        </div>
                    ) : (
                        order.map((item, keye) => {
                            return (
                                <div
                                    key={keye}
                                    className="user-order-table__rows"
                                >
                                    <div className="user-order-table__row">
                                        <div className="user-order-table__row-col user-order-table__row-article">
                                            <span className="user-order-table__row-col-key">
                                                {global._e('Kód produktu')}
                                            </span>{' '}
                                            {item.code}
                                        </div>
                                        <div className="user-order-table__row-col user-order-table__row-name">
                                            <span className="user-order-table__row-col-key">
                                                {global._e('Položka')}
                                            </span>{' '}
                                            {item.name}
                                        </div>
                                        <div className="user-order-table__row-col user-order-table__row-voc">
                                            <span className="user-order-table__row-col-key">
                                                {global._e('VOC bez DPH')}
                                            </span>{' '}
                                            {item.salePrices[0]['value'] / 100}
                                        </div>
                                        <div className="user-order-table__row-col user-order-table__row-count">
                                            <span className="user-order-table__row-col-key">
                                                {global._e('Množství')}
                                            </span>{' '}
                                            {item.position.quantity}
                                        </div>
                                        <div className="user-order-table__row-col user-order-table__row-cost">
                                            <span className="user-order-table__row-col-key">
                                                {global._e('Částka')}
                                            </span>{' '}
                                            {global.nf(
                                                (item.position.price / 100) *
                                                    item.position.quantity
                                            )}
                                        </div>
                                        <div className="user-order-table__row-col user-order-table__row-stav">
                                            <span className="user-order-table__row-col-key">
                                                {global._e('Stav')}
                                            </span>
                                            {global._e('Skladem')}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>

                <ul className="user-order-info">
                    <li className="user-order-info__item">
                        <span className="user-order-info__key">
                            {global._e('Odběratel')}:
                        </span>
                        <span className="user-order-info__value">
                            {` ${user?.name} `}
                        </span>
                    </li>
                    <li className="user-order-info__item">
                        <span className="user-order-info__key">
                            {global._e('Telefonní číslo')}:
                        </span>
                        <span className="user-order-info__value">
                            {` ${user?.phone} `}
                        </span>
                    </li>
                    <li className="user-order-info__item">
                        <span className="user-order-info__key">
                            {global._e('Email')}:
                        </span>
                        <span className="user-order-info__value">
                            {` ${user?.email} `}
                        </span>
                    </li>
                    <li className="user-order-info__item">
                        <span className="user-order-info__key">
                            {global._e('Datum')}:
                        </span>
                        <span className="user-order-info__value">
                            {` ${el.order.created} `}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
});

const OrderList = ({ orders, user }) => {
    return (
        <div className="user-orders">
            <div className="user-orders-header">
                <div className="user-orders-header__col user-orders-header__id">
                    {global._e('ID')}
                </div>
                <div className="user-orders-header__col user-orders-header__date">
                    {global._e('Datum')}
                </div>
                <div className="user-orders-header__col user-orders-header__name">
                    {global._e('Objednávka')}
                </div>
                <div className="user-orders-header__col user-orders-header__state">
                    {global._e('State')}
                </div>
                <div className="user-orders-header__col user-orders-header__more"></div>
            </div>
            {orders.map((el, key) => {
                return <OrderComponent user={user} el={el} key={el.id} />;
            })}
        </div>
    );
};
function Screen(props) {
    const { user } = props;
    // let history = useHistory();
    const [orders, setOrders] = useState([]);
    const [isLast, setIsLast] = useState(false);
    const [loading, setLoading] = useState(true);

    async function fetchData() {
        let ordersFetched = await getOrders(orders.length);
        if (ordersFetched.isLast != undefined) setIsLast(true);
        if (undefined !== ordersFetched.orders) {
            setOrders([...orders, ...ordersFetched.orders]);
        }
        // if ( offset == 0 ) {
        //   setOrders( orders );
        // } else {
        // }
        setLoading(false);
        // offset = offset + orders.length;
        // console.log(offset,orders.length)
    }
    useEffect(() => {
        // if ( false === productsInfo ) {
        //   global.Alert( 'No products in cart' );
        //   return false;
        // }
        fetchData();
        document.title = global._e('Orders');
    }, []);
    // CLIENT ADDRESS
    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <main className="main">
                        <div className="container">
                            <div className="user-story user-story-order">
                                <div className="user-story-header">
                                    {global._e('Moje objednávky')}
                                </div>
                                {orders.length ? (
                                    <OrderList orders={orders} user={user} />
                                ) : (
                                    <div
                                        className="user-story-header"
                                        style={{
                                            height: 300,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {global._e('No orders')}
                                    </div>
                                )}
                                {!isLast && orders.length ? (
                                    <div className="user-story-load__wrap">
                                        <button
                                            onClick={() => fetchData()}
                                            className="button button--solid user-story-load"
                                        >
                                            {global._e('Load more')}
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user,
});

export { OrderList };
export default connect(mapStateToProps, null)(WithAuth(Screen));
