import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import WithAuth from '../../hocs/WithAuth';
import authActions from '../../redux/actions/Auth';
import modalActions from '../../redux/actions/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputMask from 'react-input-mask';
const masks = {
    sk: ['+421 1234-567-8', '+421 9999-999-9'],
    cz: ['+420 123-456-789', '+420 999-999-999'],
};

const PhoneInput = ({ value, onChange, country }) => {
    return (
        <InputMask mask={masks[country][1]} value={value} onChange={onChange}>
            {(inputProps) => (
                <input
                    className="auth-form-block__input"
                    required
                    name="phone"
                    id="phone"
                    placeholder={masks[country][0]}
                    {...inputProps}
                    type="tel"
                    disableUnderline
                />
            )}
        </InputMask>
    );
};
function Screen(props) {
    let history = useHistory();
    const [country, setCountry] = useState('cz');
    const [password, setPassword] = useState('');
    const { actions, authenticated } = props;

    const handleSubmit = async (event) => {
        event.preventDefault();
        const finalData = {};
        const data = new FormData(event.target);
        for (let [key, value] of data.entries()) {
            finalData[key] = value;
        }
        if (finalData['phone'].replace(/\D+/g, '').length < 9) {
            actions.setErrorMessage(_e('Wrong phone number'));
            return false;
        }
        if (finalData['password'] !== finalData['password_confirmation']) {
            actions.setErrorMessage(_e('Passwords do not match'));
            return false;
        }
        // console.log({ finalData });
        const res = await actions.register(finalData);
        if (res) {
            actions.setMessage(_e('Registration success message'));
            history.push('/');
        }
    };
    useEffect(() => {
        // if ( false === productsInfo ) {
        //   actions.setErrorMessage( 'No products in cart' );
        //   return false;
        // }
        if (authenticated) {
            history.push('/');
        }
        document.title = _e('Register');
    }, []);
    // CLIENT ADDRESS
    return (
        <>
            {authenticated ? (
                <Redirect to="/" />
            ) : (
                <main className="main">
                    <div className="container">
                        <div className="auth-block register">
                            <div className="auth-header__wrap">
                                <div className="auth-header">
                                    <div className="auth-header__icon">
                                        <img
                                            src="./assets/img/auth/register.png"
                                            alt="Registrace"
                                        />
                                    </div>
                                    <div className="auth-header__text">
                                        {_e('Register')}
                                    </div>
                                </div>
                            </div>
                            <div className="auth-subheader">
                                {_e('All fields are required')}
                            </div>
                            <div className="auth-form__wrap">
                                <form
                                    onSubmit={handleSubmit}
                                    action=""
                                    className="auth-form"
                                >
                                    <div className="auth-form-block">
                                        <label
                                            htmlFor="name"
                                            className="auth-form-block__label"
                                        >
                                            {_e('Name')}
                                        </label>
                                        <input
                                            id="name"
                                            name="name"
                                            required
                                            minLength={5}
                                            type="text"
                                            className="auth-form-block__input"
                                        />
                                    </div>
                                    <div className="auth-form-block">
                                        <label
                                            htmlFor="email"
                                            className="auth-form-block__label"
                                        >
                                            {_e('E-Mail Address')}
                                        </label>
                                        <input
                                            id="email"
                                            name="email"
                                            required
                                            minLength={5}
                                            type="email"
                                            className="auth-form-block__input"
                                        />
                                    </div>
                                    <div className="auth-form-block auth-form-country">
                                        <div className="auth-form-block__label">
                                            {_e('Phone')}
                                        </div>
                                        <div className="auth-form-country__right">
                                            <div className="select">
                                                <input
                                                    className="select__value"
                                                    id="country"
                                                    name="country-phone"
                                                    type="hidden"
                                                    value="cz"
                                                />
                                                <div className="select__container">
                                                    <div className="select__curr closed">
                                                        <img
                                                            src={
                                                                country === 'cz'
                                                                    ? './assets/img/auth/country.png'
                                                                    : './assets/img/auth/slovakia.png'
                                                            }
                                                            alt={country}
                                                        />
                                                    </div>
                                                    <ul className="select__list">
                                                        <li
                                                            onClick={() =>
                                                                setCountry('cz')
                                                            }
                                                            className="select__item"
                                                            data-value="cz"
                                                        >
                                                            <img
                                                                src="./assets/img/auth/country.png"
                                                                alt="cz"
                                                            />
                                                        </li>
                                                        <li
                                                            onClick={() =>
                                                                setCountry('sk')
                                                            }
                                                            className="select__item"
                                                            data-value="sk"
                                                        >
                                                            <img
                                                                src="./assets/img/auth/slovakia.png"
                                                                alt="sk"
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <input
                                                id="phone"
                                                name="phone"
                                                required
                                                minLength={5}
                                                type="phone"
                                                className="auth-form-block__input"
                                                placeholder={masks[country][0]}
                                                disableUnderline
                                            />
                                            {/*<PhoneInput country={country} />*/}
                                        </div>
                                    </div>
                                    <div className="auth-form-block auth-form-radios">
                                        <div className="auth-form-block__label">
                                            {_e('Stat')}
                                        </div>
                                        <div className="auth-form-radios__right">
                                            <div className="radio">
                                                <label className="radio__label">
                                                    <input
                                                        id="lang"
                                                        name="lang"
                                                        className="radio__input"
                                                        type="radio"
                                                        required
                                                        checked={
                                                            country === 'cz'
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={() =>
                                                            setCountry('cz')
                                                        }
                                                        value="0"
                                                    />
                                                    <span className="radio__check"></span>
                                                    <span className="radio__title">
                                                        CZ
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="radio">
                                                <label className="radio__label">
                                                    <input
                                                        className="radio__input"
                                                        type="radio"
                                                        checked={
                                                            country === 'sk'
                                                                ? true
                                                                : false
                                                        }
                                                        id="lang"
                                                        name="lang"
                                                        required
                                                        onChange={() =>
                                                            setCountry('sk')
                                                        }
                                                        value="1"
                                                    />
                                                    <span className="radio__check"></span>
                                                    <span className="radio__title">
                                                        SK
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="auth-form-block">
                                        <label
                                            htmlFor="city"
                                            className="auth-form-block__label"
                                        >
                                            {_e('City')}
                                        </label>
                                        <input
                                            type="text"
                                            id="city"
                                            name="city"
                                            required
                                            className="auth-form-block__input"
                                        />
                                    </div>
                                    <div className="auth-form-block">
                                        <label
                                            htmlFor="ico"
                                            className="auth-form-block__label"
                                        >
                                            {_e('ICO')}
                                        </label>
                                        <input
                                            id="ico"
                                            name="ico"
                                            required
                                            type="text"
                                            className="auth-form-block__input"
                                        />
                                    </div>
                                    <div className="auth-form-block">
                                        <label
                                            htmlFor="okpo"
                                            className="auth-form-block__label"
                                        >
                                            {_e('DIČ')}
                                        </label>
                                        <input
                                            id="okpo"
                                            name="okpo"
                                            required
                                            type="text"
                                            className="auth-form-block__input"
                                        />
                                    </div>
                                    <div className="auth-form-block">
                                        <label
                                            htmlFor="legal_name"
                                            className="auth-form-block__label"
                                        >
                                            {_e('Legal name')}
                                        </label>
                                        <input
                                            id="legal_name"
                                            name="legal_name"
                                            required
                                            type="text"
                                            className="auth-form-block__input"
                                        />
                                    </div>
                                    <div className="auth-form-block">
                                        <label
                                            htmlFor="password"
                                            className="auth-form-block__label"
                                        >
                                            {_e('Password')} (min5)
                                        </label>
                                        <input
                                            minLength={5}
                                            required
                                            id="password"
                                            name="password"
                                            type="password"
                                            className="auth-form-block__input"
                                        />
                                    </div>
                                    <div className="auth-form-block">
                                        <label
                                            htmlFor="password_confirmation"
                                            className="auth-form-block__label"
                                        >
                                            {_e('Confirm Password')}
                                        </label>
                                        <input
                                            minLength={5}
                                            required
                                            id="password_confirmation"
                                            name="password_confirmation"
                                            type="password"
                                            className="auth-form-block__input"
                                        />
                                    </div>
                                    <div className="auth-form-checkbox auth-form-personal">
                                        {/*<div className="radio">*/}
                                        {/*    <label className="radio__label">*/}
                                        {/*        <input*/}
                                        {/*            name="agree"*/}
                                        {/*            className="radio__input"*/}
                                        {/*            type="checkbox"*/}
                                        {/*        />*/}
                                        {/*        <span className="radio__check"></span>*/}
                                        {/*        <span className="radio__title">*/}
                                        {/*            {_e('Agree')}*/}
                                        {/*        </span>*/}
                                        {/*    </label>*/}
                                        {/*</div>*/}
                                        {/*<a*/}
                                        {/*    href="#"*/}
                                        {/*    className="auth-form-personal__link"*/}
                                        {/*>*/}
                                        {/*    {_e(*/}
                                        {/*        'условия пользовательского договора'*/}
                                        {/*    )}*/}
                                        {/*</a>*/}
                                    </div>
                                    <div className="auth-form-register__btn-wrap">
                                        <button className="auth-form-register__btn button button--solid">
                                            {_e('Register')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    authenticated: state.authReducer.authenticated,
});

const ActionCreators = Object.assign({}, authActions, modalActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

// export default WithNavigation(MainScreen, true, false);
export default connect(mapStateToProps, mapDispatchToProps)(WithAuth(Screen));
