const clearSteps = () => {
    return {
        type: 'CLEAR_STEPS',
    };
};
// STEP 1

const setStepOneValidated = (data) => {
    return {
        type: 'SET_STEP_ONE',
        payload: data,
    };
};

const setStepOne = (data) => {
    return async (dispatch, getState) => {
        if (
            data?.paymentMethod === undefined ||
            data?.deliveryMethod === undefined ||
            data?.dropShipping === undefined
        ) {
            global.Alert('Error storing Step 1 data');
            return false;
        }
        dispatch(setStepOneValidated(data));
        return true;
    };
};

const getStepOne = () => {
    return async (dispatch, getState) => {
        const stepOne = getState().stepReducer?.stepOne;
        if (!global.empty(stepOne)) return stepOne;
        return false;
    };
};

// STEP 1
// STEP 2

const setStepTwoValidated = (data) => {
    return {
        type: 'SET_STEP_TWO',
        payload: data,
    };
};

const getDropShipping = () => {
    return async (dispatch, getState) => {
        const stepOne = getState().stepReducer?.stepOne;
        if (!global.empty(stepOne)) {
            return stepOne?.dropShipping;
        }
        return false;
    };
};

const getStepTwo = () => {
    return async (dispatch, getState) => {
        const stepTwo = getState().stepReducer?.stepTwo;
        if (!global.empty(stepTwo)) return stepTwo;
        return false;
    };
};

const canStepTwo = () => {
    return async (dispatch, getState) => {
        const stepOne = getState().stepReducer?.stepOne;
        if (!global.empty(stepOne)) return true;
        return false;
    };
};

const setStepTwo = (data) => {
    return async (dispatch, getState) => {
        const stepTwo = getState().stepReducer?.stepTwo;
        let newStepTwo = {};
        if (stepTwo) {
            if (undefined !== data.clientAddress) {
                newStepTwo = {
                    clientAddress: data.clientAddress || {},
                    userAddress: stepTwo.userAddress || {},
                };
            } else {
                newStepTwo = {
                    clientAddress: stepTwo.clientAddress || {},
                    userAddress: data.userAddress || {},
                };
            }
        } else {
            newStepTwo = {
                clientAddress: data.clientAddress || {},
                userAddress: data.userAddress || {},
            };
        }
        dispatch(setStepTwoValidated(newStepTwo));
        return true;
    };
};

// STEP 2

// STEP 3

const canStepThree = () => {
    return async (dispatch, getState) => {
        const stepTwo = getState().stepReducer?.stepTwo;
        const stepOne = getState().stepReducer?.stepOne;
        if (!global.empty(stepTwo)) {
            const samovivoz =
                stepOne?.deliveryMethod?.ms_id ===
                '37424b33-ed6a-11eb-0a80-05ec0020a989';
            if (
                +stepTwo?.userAddress > 0 &&
                (samovivoz ||
                    stepTwo?.clientAddress?.client_name?.length > 0 ||
                    +stepTwo?.clientAddress > 0)
            ) {
                return true;
            }
        }
        return false;
    };
};

const getStepThree = () => {
    return async (dispatch, getState) => {
        const stepThree = getState().stepReducer?.stepThree;
        if (!global.empty(stepThree)) return stepThree;
        return false;
    };
};
const setStepThree = (data) => {
    return {
        type: 'SET_STEP_THREE',
        payload: data,
    };
};

// STEP 3

export default {
    setStepOne,
    getStepOne,
    getStepTwo,
    canStepTwo,
    getDropShipping,
    setStepTwo,
    canStepThree,
    setStepThree,
    getStepThree,
    clearSteps,
};
