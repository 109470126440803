import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getOrder } from '../actions/Order';
import Loader from '../components/Loader';
import WithAuth from '../hocs/WithAuth';

function Screen() {
    // let history = useHistory();
    let { orderId } = useParams();
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // if ( false === productsInfo ) {
        //   global.Alert( 'No products in cart' );
        //   return false;
        // }
        async function fetchData() {
            let order = await getOrder(orderId);
            console.log(order);
            setOrder(order);
            setLoading(false);
        }
        fetchData();
        document.title = global._e('Orders');
    }, []);
    // CLIENT ADDRESS
    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <h1 className="text-center">Order {orderId}</h1>
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <td>Code</td>
                                <td>Title</td>
                                <td>VOC bez DPH</td>
                                <td>Vat</td>
                                <td>Množství</td>
                                <td>Částka</td>
                            </tr>
                        </thead>
                        <tbody>
                            {order.map((el, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{el.code}</td>
                                        <td>{el.name}</td>
                                        <td>
                                            {el.salePrices[0]['value'] / 100}
                                        </td>
                                        <td>{el.vat}</td>
                                        <td>{el.position.quantity}</td>
                                        <td>
                                            {(el.position.price / 100) *
                                                el.position.quantity}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default WithAuth(Screen);
