import React, { useEffect, useState } from 'react';
import {
    HashRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import './App.css';
import './App.custom.css';
import Swiper from 'swiper';
import './assets/js/main.js';
import 'swiper/swiper-bundle.css';

import Login from './screens/auth/Login';
import Register from './screens/auth/Register';
import StepOne from './screens/StepOne';
import StepZero from './screens/StepZero';
import StepTwo from './screens/StepTwo';
import Home from './screens/Home';
import StepThree from './screens/StepThree';
import HeaderBar from './components/partials/HeaderBar';
import Footer from './components/partials/Footer';
import Orders from './screens/Orders';
import NotAuth from './screens/NotAuth';
import Catalog from './screens/static/Catalog';
import Order from './screens/Order';
import ProfileAddresses from './screens/ProfileAddresses';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import Loader from './components/Loader';
import basicActions from './redux/actions/Basic';
import { bindActionCreators } from 'redux';

function App(props) {
    const { actions } = props;
    const [initialised, setInitialised] = useState(false);
    const initialise = async () => {
        await actions.getBasic();
        await global.init();
        setInitialised(true);
    };
    useEffect(() => {
        initialise();
    }, []);
    return (
        <>
            {initialised ? (
                <Router>
                    <div
                        className={
                            props.authenticated === true
                                ? 'layout '
                                : 'layout auth'
                        }
                    >
                        <HeaderBar />
                        <ToastContainer />
                        <Switch>
                            <Route path="/login">
                                <Login />
                            </Route>
                            <Route path="/register">
                                <Register />
                            </Route>
                            {/*<Route path="/not-authenticated">*/}
                            {/*    <NotAuth />*/}
                            {/*</Route>*/}
                            <PrivateRoute
                                authenticated={props.authenticated}
                                path="/addresses"
                                component={ProfileAddresses}
                            />
                            <PrivateRoute
                                authenticated={props.authenticated}
                                path="/catalog"
                                component={Catalog}
                            />
                            <PrivateRoute
                                authenticated={props.authenticated}
                                path="/orders/:orderId"
                                component={Order}
                            />
                            <PrivateRoute
                                authenticated={props.authenticated}
                                path="/orders"
                                component={Orders}
                            />
                            <PrivateRoute
                                authenticated={props.authenticated}
                                path="/step_3"
                                component={StepThree}
                            />
                            <PrivateRoute
                                authenticated={props.authenticated}
                                path="/step_2"
                                component={StepTwo}
                            />
                            <PrivateRoute
                                authenticated={props.authenticated}
                                path="/step_1"
                                component={StepOne}
                            />
                            <PrivateRoute
                                authenticated={props.authenticated}
                                path="/step_0"
                                component={StepZero}
                            />
                            <PrivateRoute
                                authenticated={props.authenticated}
                                path="/"
                                component={Home}
                            />
                        </Switch>
                        <Footer />
                    </div>
                </Router>
            ) : (
                <Loader />
            )}
        </>
    );
}
function PrivateRoute({ component: Component, authenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                authenticated === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                        }}
                    />
                )
            }
        />
    );
}

const ActionCreators = Object.assign({}, basicActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

const mapStateToProps = (state) => ({
    authenticated: state.authReducer.authenticated,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
