const initialState = {
    callModal: false,
    termsModal: false,
    errorMessage: '',
    confirm: '',
    confirmAction: () => {},
    message: '',
};

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_CALL_MODAL':
            return {
                callModal: !state.callModal,
            };
        case 'TOGGLE_TERMS_MODAL':
            return {
                termsModal: !state.termsModal,
            };
        case 'SET_ERROR_MESSAGE':
            return {
                errorMessage: action.payload,
            };
        case 'SET_CONFIRM':
            return {
                confirm: action.payload.text,
                confirmAction: action.payload.callback,
            };
        case 'SET_MESSAGE':
            return {
                message: action.payload,
            };
        default:
            return state;
    }
};
export default modalReducer;
