import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Radio from '../components/common/Radio';
import Select from '../components/common/Select';
import FormHeader from '../components/FormHeader';
import { getPaymentMethods, getDeliveryMethods } from '../actions/Common';
import WithAuth from '../hocs/WithAuth';
import stepActions from '../redux/actions/Step';
import modalActions from '../redux/actions/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const radioOptions = [
    {
        title: _e('Ano'),
        val: 1,
        // description: _e('Dropshipping yes description'),
    },
    {
        val: 0,
        title: _e('Ne'),
        // description: _e('Dropshipping no description'),
    },
];

function Screen(props) {
    const { actions, stepOne, location, orderProducts } = props;
    let history = useHistory();
    const [deliveryMethod, setDeliveryMethod] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [deliveryMethods, setDeliveryMethods] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [dropShipping, setDropShipping] = useState(false);
    const [canContinue, setCanContinue] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        async function fetchData() {
            // if (location?.state?.fromDashboard === true) {
            //     const canStepThree = await actions.canStepThree();
            //     if (canStepThree) {
            //         if (
            //             orderProducts &&
            //             Object.keys(orderProducts).length > 0
            //         ) {
            //             history.push('step_3');
            //             return;
            //         }
            //     }
            // }
            const paymentMethods = await getPaymentMethods();
            const deliveryMethods = await getDeliveryMethods();
            setPaymentMethods(paymentMethods);
            setDeliveryMethods(deliveryMethods);
            //default values selected
            // setPaymentMethod( paymentMethods[ 0 ][ 'id' ] );
            // setDeliveryMethod( deliveryMethods[ 0 ][ 'id' ] );
            //default values selected
            fillFromCache();
            // setTimeout(() => {
            // });
        }
        fetchData();
        document.title = _e('Doprava a platba');
        // let canContinue = global.getCanStep2();
        setCanContinue(true);
    }, []);

    const fillFromCache = () => {
        if (stepOne) {
            setPaymentMethodAlt(stepOne?.paymentMethod);
            setDeliveryMethodAlt(stepOne?.deliveryMethod);
            setDropShipping(stepOne?.dropShipping);
        }
        setLoading(false);
    };

    const validate = () => {
        let isValid = true;
        if (!global.validateField(deliveryMethod?.title, 1)) {
            // setDeliveryMethod('error');
            isValid = false;
        }
        if (!global.validateField(paymentMethod?.title, 1)) {
            // setPaymentMethod('error');
            isValid = false;
        }
        if (!global.validateField(dropShipping, 1)) {
            // setDropShipping('error');
            isValid = false;
        }
        return isValid;
    };

    const handleChange = (el, func) => {
        func(el);
    };
    const setPaymentMethodAlt = (el) => {
        setPaymentMethod(Object.assign({}, el));
        // setPaymentMethod(el);
    };
    const setDeliveryMethodAlt = (el) => {
        // alert('selecting object deli' + el?.id);
        // console.log({ el, deliveryMethod });
        // console.log('ask start');
        // console.log('ask end');
        setDeliveryMethod(Object.assign({}, el));
        // setTimeout(() => {
        // console.log({ el, deliveryMethod });
        // }, 2800);
    };
    const clearSteps = async () => {
        await actions.clearSteps();
        history.push('step_2');
    };
    const submit = async () => {
        let validated = validate();
        if (!validated) {
            actions.setErrorMessage(_e('Prosím upřesněte hodnoty'));
            return false;
        }
        let drop = dropShipping == 1 ? true : false;
        let data = {
            paymentMethod,
            deliveryMethod,
            dropShipping: drop,
        };
        let resp = await actions.setStepOne(data);
        if (resp) {
            setCanContinue(true);
            history.push('step_2');
            return false;
        }
    };
    return (
        <div className="order">
            <FormHeader
                loading={loading}
                step="1"
                title={_e('Doprava a platba')}
                canContinue={canContinue}
            />
            <div className="order-content-1">
                <div className="container">
                    <form action="" className="order-delivery__form">
                        <div className="order-clear">
                            <a
                                onClick={clearSteps}
                                className="order-clear__link pointer"
                            >
                                Clear
                            </a>
                        </div>
                        <div className="order-delivery">
                            <div className="order-delivery__selects">
                                {paymentMethods && (
                                    <Select
                                        key={1}
                                        caption={_e('Způsob platby')}
                                        className="order-delivery__select"
                                        options={paymentMethods}
                                        value={
                                            paymentMethod ?? paymentMethods[0]
                                        }
                                        onChange={(e) =>
                                            handleChange(e, setPaymentMethodAlt)
                                        }
                                    />
                                )}
                                {deliveryMethods && (
                                    <Select
                                        key={2}
                                        caption={_e('Způsob doručení')}
                                        className="order-delivery__select"
                                        options={deliveryMethods}
                                        value={
                                            deliveryMethod ?? deliveryMethods[0]
                                        }
                                        onChange={(e) =>
                                            handleChange(
                                                e,
                                                setDeliveryMethodAlt
                                            )
                                        }
                                    />
                                )}
                            </div>
                            <div className="order-delivery__radios-wrap">
                                <div className="order-delivery__radios-header">
                                    {_e('Dropshipping')}
                                </div>
                                <div className="order-delivery__radios">
                                    <Radio
                                        options={radioOptions}
                                        className="order-delivery__radio"
                                        initialVal={
                                            dropShipping === true ? 1 : 0
                                        }
                                        radioName="dropshipping"
                                        onChange={(val) =>
                                            handleChange(val, setDropShipping)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="order-delivery__button-wrap">
                            <button
                                type="button"
                                onClick={submit}
                                className="button button--solid order-delivery__button"
                            >
                                {_e('Pokrakovat')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    stepOne: state.stepReducer.stepOne,
    orderProducts: state.orderReducer.products,
});

const ActionCreators = Object.assign({}, stepActions, modalActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithAuth(Screen));
