const initialState = {
    loading: false,
    loaded: false,
    catalog: [],
    export: null,
    feed: null,
    terms: null,
    error: false,
};

const staticReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_STATIC':
            return {
                ...state,
                loading: action.payload.loading ?? false,
                loaded: action.payload.loaded ?? false,
                catalog: action.payload.catalog ?? null,
                export: action.payload.export ?? null,
                feed: action.payload.feed ?? null,
                terms: action.payload.terms ?? null,
                error: action.payload.error ?? true,
            };
        default:
            return state;
    }
};
export default staticReducer;
