import React, { useEffect } from 'react';
import Loader from '../../components/Loader';
import staticActions from '../../redux/actions/Static';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const Screen = (props) => {
    const { loading, actions, error, loaded, catalog } = props;
    useEffect(() => {
        actions.getStatic();
    }, []);
    return (!loaded && !catalog?.length) || null === catalog ? (
        <>
            <h1 className="text-center user-story-header">{_e('Catalog')}</h1>
            <Loader />
        </>
    ) : (
        <div className="container">
            <h1 className="text-center user-story-header">{_e('Catalog')}</h1>
            <div className="catalog-wrapper">
                {catalog.map((el, key) => {
                    return (
                        <div className="catalog-item text-center">
                            <img
                                className="catalog-image"
                                src={el.icon_absolute_url}
                                alt=""
                            />
                            <span className="catalog-text">{el.title}</span>
                            <a
                                className="catalog-button"
                                href={el.pdf_absolute_url}
                                download={el.title}
                            >
                                {global._e('Скачать')}
                            </a>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    loading: state.staticReducer.loading,
    loaded: state.staticReducer.loaded,
    catalog: state.staticReducer.catalog,
    error: state.staticReducer.error,
});

const ActionCreators = Object.assign({}, staticActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
