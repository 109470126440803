const toggleModal = () => {
    return {
        type: 'TOGGLE_CALL_MODAL',
    };
};

const setErrorMessage = (message) => {
    return {
        type: 'SET_ERROR_MESSAGE',
        payload: message,
    };
};

const setMessage = (message) => {
    return {
        type: 'SET_MESSAGE',
        payload: message,
    };
};

const toggleTermsModal = () => {
    return {
        type: 'TOGGLE_TERMS_MODAL',
    };
};

export const toggleTermsModalAction = toggleTermsModal;

const setConfirm = (data) => {
    return {
        type: 'SET_CONFIRM',
        payload: data,
    };
};
export default {
    toggleModal,
    toggleTermsModal,
    setErrorMessage,
    setMessage,
    setConfirm,
};
