import React, { useState, useEffect } from 'react';
import {
    updateClientAddress,
    createClientAddress,
    validateClientAddress,
} from '../actions/ClientAddress';
import SelectStat from '../components/common/SelectStat';

export default function Component(props) {
    const [invalid, setInvalid] = useState([]);
    const [clientAddress, setClientAddress] = useState({
        client_name: '',
        ulice: '',
        stat: '',
        mesto: '',
        psc: '',
        telefon: '',
        email: '',
        cash_on_delivery: '',
    });

    useEffect(() => {
        let validation = validateClientAddress(
            clientAddress,
            props.isDropShipping
        );
        if (validation === true) {
            setInvalid([]);
            if (props.onValidated !== false) {
                props.onValidated(clientAddress);
            }
        } else if (
            props.onValidated !== false &&
            Object.keys(clientAddress).length > 0
        ) {
            setInvalid(validation);
            if (props.onValidated !== false) {
                // props.onValidated({ clientAddress: '' });
            }
        }
    }, [clientAddress]);

    useEffect(() => {
        setInvalid([]);
        if (props.clientAddress) {
            setClientAddress(props.clientAddress);
        }
    }, [props]);

    const submit = async () => {
        let validation = validateClientAddress(
            clientAddress,
            props.isDropShipping
        );
        if (true !== validation) {
            setInvalid(validation);
        } else {
            setInvalid([]);
            if (props.action == 'edit') {
                await updateClientAddress(clientAddress);
            } else {
                await createClientAddress(clientAddress);
            }
            props.onSubmit();
        }
    };
    const handleChange = (e, key) => {
        if (key === 'stat') {
            e = {
                target: {
                    value: e,
                },
            };
        }
        if (undefined == e.target) {
            setClientAddress({ ...clientAddress, [key]: +e });
        } else {
            setClientAddress({ ...clientAddress, [key]: e.target.value });
        }
    };

    return (
        <div className="order-address">
            <div className="order-address-cols">
                <div className="order-address-left">
                    <div className="input-block">
                        <input
                            autoFocus={true}
                            placeholder="Jméno a přijmení (název firmy)"
                            value={clientAddress['client_name']}
                            onChange={(e) => handleChange(e, 'client_name')}
                            className={`input-block__field ${
                                invalid.includes('client_name') && 'is-invalid'
                            }`}
                            required=""
                        />
                    </div>
                    <div className="input-block">
                        <input
                            placeholder="Ulice a číslo popisné"
                            value={clientAddress['ulice']}
                            onChange={(e) => handleChange(e, 'ulice')}
                            className={`input-block__field ${
                                invalid.includes('ulice') && 'is-invalid'
                            }`}
                            required=""
                        />
                    </div>
                    <div className="input-block">
                        <SelectStat
                            value={clientAddress['stat']}
                            onChange={(e) => handleChange(e, 'stat')}
                            className={
                                invalid.includes('stat') ? 'is-invalid' : ''
                            }
                        />
                    </div>
                </div>
                <div className="order-address-right">
                    <div className="order-address-right__row">
                        <div className="input-block">
                            <input
                                placeholder="Město"
                                value={clientAddress['mesto']}
                                onChange={(e) => handleChange(e, 'mesto')}
                                className={`input-block__field ${
                                    invalid.includes('mesto') && 'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                        <div className="input-block">
                            <input
                                placeholder="PSČ"
                                value={clientAddress['psc']}
                                onChange={(e) => handleChange(e, 'psc')}
                                className={`input-block__field ${
                                    invalid.includes('psc') && 'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                    </div>
                    <div className="order-address-right__row">
                        <div className="input-block">
                            <input
                                placeholder="Telefon"
                                value={clientAddress['telefon']}
                                onChange={(e) => handleChange(e, 'telefon')}
                                className={`input-block__field ${
                                    invalid.includes('telefon') && 'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                        <div className="input-block">
                            <input
                                placeholder="Kontaktní email"
                                value={clientAddress['email']}
                                onChange={(e) => handleChange(e, 'email')}
                                className={`input-block__field ${
                                    invalid.includes('email') && 'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                        {
                            props.isDropShipping === true ? (
                                <div className="input-block">
                                    <input
                                        placeholder="Částka dobirky"
                                        value={
                                            clientAddress['cash_on_delivery']
                                        }
                                        onChange={(e) =>
                                            handleChange(e, 'cash_on_delivery')
                                        }
                                        className={`input-block__field ${
                                            invalid.includes(
                                                'cash_on_delivery'
                                            ) && 'is-invalid'
                                        }`}
                                        required=""
                                    />
                                </div>
                            ) : null
                            // <div className="order-address-button__wrap">
                            //     <button
                            //         className="button button--solid order-address-button"
                            //         onClick={submit}
                            //     >
                            //         {_e('Save address')}
                            //     </button>
                            // </div>
                        }
                    </div>
                </div>
            </div>
            {!props.isDropShipping && (
                <div className="order-address-button__wrap">
                    <button
                        className="button button--solid order-address-button"
                        onClick={submit}
                        type="button"
                    >
                        {_e('Save address')}
                    </button>
                </div>
            )}
        </div>
    );
}
