import axios from 'axios';

axios.defaults.withCredentials = true;

export const getProducts = async (data) => {
    let header = await global.getHeader();
    let res = await axios
        .post(`${global.API_ADDRESS}/api/getProducts`, data, header)
        .catch(global.handleError);
    return res.data;
};

export const createOrder = async (data) => {
    let header = await global.getHeader();
    let res = await axios
        .post(`${global.API_ADDRESS}/api/createOrder`, data, header)
        .catch(global.handleError);
    return res?.data;
};
