import productActions from './Product';

const clearOrderState = (state) => {
    return {
        type: 'CLEAR_ORDER_STATE',
        payload: state,
    };
};
const setOrderProducts = (state) => {
    return {
        type: 'SET_ORDER_PRODUCTS',
        payload: state,
    };
    // if (direction === 'plus') {
    //     return {
    //         type: 'INCREMENT_PRODUCT',
    //         payload: { product },
    //     };
    // } else {
    //     return {
    //         type: 'DECREMENT_PRODUCT',
    //         payload: { product },
    //     };
    // }
};
const setCartFullState = (state) => {
    return {
        type: 'SET_CART_FULL_STATE',
        payload: state,
    };
};
const checkHideOrderedAndSetOrder = (state) => {
    return async (dispatch, getState) => {
        const basicInfo = getState().basicReducer;
        const newState = {
            products: state,
            totalPrices: {},
            totalCount: 0,
        };
        for (let key in newState['products']) {
            if (newState['products'][key]['count'] <= 0) {
                delete newState['products'][key];
            }
        }
        if (Object.keys(newState['products']).length < 1) {
            await dispatch(setCartFullState(false));
            await dispatch(productActions.hideOrders());
        } else {
            let totalPriceSk = 0;
            let totalPriceCz = 0;
            let totalCount = 0;
            Object.values(newState['products']).map((prod, key) => {
                let priceSk = prod['eur'];
                let priceCz = prod['voc'];
                let count = prod['count'];
                let totalPriceSkRow = Math.round(+priceSk * +count, 1);
                let totalPriceCzRow = Math.round(+priceCz * +count, 1);
                totalPriceSk += totalPriceSkRow;
                totalPriceCz += totalPriceCzRow;
                totalCount += +count;
                newState['products'][prod['external_id']][
                    'totalCz'
                ] = totalPriceCzRow;
                newState['products'][prod['external_id']][
                    'totalSk'
                ] = totalPriceSkRow;
            });
            newState['totalPrices'] = {
                cz: totalPriceCz,
                sk: totalPriceSk,
            };
            newState['totalCount'] = totalCount;
            let deliveryPrice = 0;
            const deliveryUntil =
                basicInfo?.delivery_min?.[global.locale] -
                newState['totalPrices']?.[global.locale];
            if (deliveryUntil > 0) {
                deliveryPrice = basicInfo?.delivery_price?.[global.locale];
            }
            newState['deliveryPrice'] = deliveryPrice;
            await dispatch(setCartFullState(true));
        }
        global.jQueryHandleStickyOrderFooter();
        return dispatch(setOrderProducts(newState));
    };
};
const setOrderExact = (product, count) => {
    return async (dispatch, getState) => {
        const counte = Number(count);
        let newState = null;
        if (counte < 1 || isNaN(counte)) {
            newState = {
                ...getState()?.orderReducer?.products,
                [product.external_id]: {
                    ...product,
                    count: 0,
                },
            };
        } else {
            newState = {
                ...getState()?.orderReducer?.products,
                [product.external_id]: {
                    ...product,
                    count: counte,
                },
            };
        }
        if (Object.values(newState).length) {
            await dispatch(checkHideOrderedAndSetOrder(newState));
        }
    };
};
const changeOrder = (product, direction = 'plus') => {
    return async (dispatch, getState) => {
        // console.log(
        //     getState()?.orderReducer?.products[product.external_id]?.count
        // );
        if (
            undefined !==
            getState()?.orderReducer?.products[product.external_id]
        ) {
            if (direction === 'plus') {
                await dispatch(
                    checkHideOrderedAndSetOrder({
                        ...getState()?.orderReducer?.products,
                        [product.external_id]: {
                            ...product,
                            count:
                                getState()?.orderReducer?.products[
                                    product.external_id
                                ].count + 1,
                        },
                    })
                );
            } else {
                await dispatch(
                    checkHideOrderedAndSetOrder({
                        ...getState()?.orderReducer?.products,
                        [product.external_id]: {
                            ...product,
                            count: Math.max(
                                getState()?.orderReducer?.products[
                                    product.external_id
                                ].count - 1,
                                0
                            ),
                        },
                    })
                );
            }
        } else {
            if (direction === 'plus') {
                await dispatch(
                    checkHideOrderedAndSetOrder({
                        ...getState()?.orderReducer?.products,
                        [product.external_id]: {
                            ...product,
                            count: 1,
                        },
                    })
                );
            } else {
                await dispatch(
                    checkHideOrderedAndSetOrder({
                        ...getState()?.orderReducer?.products,
                        [product.external_id]: {
                            ...product,
                            count: 0,
                        },
                    })
                );
            }
        }
        // await dispatch(
        //     setProducts(
        //         [],
        //         getState()?.productReducer?.cats,
        //         getState()?.productReducer?.total ?? 0,
        //         false
        //     )
        // );
        // try {
        //     const url = `${global.API_ADDRESS}/api/products/${categoryId}`;
        //     let res = await axios.get(url).catch((error) => {
        //         console.log(error);
        //         global.Alert(error.toString());
        //     });
        //     await dispatch(
        //         setProducts(res?.data?.products, res?.data?.cats, 0, false)
        //     );
        //     return res?.data || [];
        // } catch (error) {
        //     global.Alert(error.toString());
        //     console.error(error);
        // }
    };
};

export default { changeOrder, setOrderExact, clearOrderState };
