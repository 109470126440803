const setProfileDropShippingTrue = () => {
    return {
        type: 'SET_PROFILE_DROPSHIPPING_TRUE',
    };
};
const setProfileDropShippingFalse = () => {
    return {
        type: 'SET_PROFILE_DROPSHIPPING_FALSE',
    };
};
const toggleProfileDropShipping = () => {
    return {
        type: 'TOGGLE_PROFILE_DROPSHIPPING',
    };
};

export default {
    setProfileDropShippingTrue,
    toggleProfileDropShipping,
    setProfileDropShippingFalse,
};
