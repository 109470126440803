import React, { useState, useEffect } from 'react';
import {
    updateUserAddress,
    createUserAddress,
    validateAddress,
    getUserDefaultAddressFields,
} from '../actions/Address';
import SelectStat from '../components/common/SelectStat';

export default function Component(props) {
    const [invalid, setInvalid] = useState([]);
    const [defaultFields, setDefaultFields] = useState({});
    const [userAddress, setUserAddress] = useState({
        is_dropshipping: false,
        company_name: '',
        ico: '',
        dic: '',
        dic_check: 0,
        ulice: '',
        stat: 'CZ',
        mesto: '',
        psc: '',
        telefon: '',
        email: '',
        bank_code: '',
        bank_prefix: '',
        bank_account_number: '',
    });

    useEffect(() => {
        setInvalid([]);
        if (props.action == 'edit') {
            setUserAddress(props.userAddressEdit);
        }
    }, [props]);
    useEffect(() => {
        const fetchDefaultFields = async () => {
            if (props.action == 'add') {
                const defaultFieldsRemote = await getUserDefaultAddressFields();
                setUserAddress({
                    ...userAddress,
                    dic: defaultFieldsRemote?.okpo,
                    telefon: defaultFieldsRemote?.phone,
                    company_name: defaultFieldsRemote?.legal_name,
                    ico: defaultFieldsRemote?.ico,
                    mesto: defaultFieldsRemote?.city,
                    email: defaultFieldsRemote?.email,
                });
            }
        };
        fetchDefaultFields();
    }, []);

    const submit = async () => {
        let validation = validateAddress(userAddress);
        if (true !== validation) {
            setInvalid(validation);
        } else {
            setInvalid([]);
            if (props.action == 'edit') {
                await updateUserAddress(userAddress);
            } else {
                await createUserAddress(userAddress);
            }
            global.Alert('done', 'success');
            props.onSubmit();
        }
    };

    const handleChange = (e, key) => {
        console.log(e);
        if (key === 'stat') {
            e = {
                target: {
                    value: e,
                },
            };
        }
        console.log(e);
        if (props.action == 'edit') {
            if (undefined == e.target) {
                setUserAddress({ ...userAddress, [key]: +e });
            } else {
                setUserAddress({ ...userAddress, [key]: e.target.value });
            }
        } else {
            if (undefined == e.target) {
                setUserAddress({
                    ...userAddress,
                    [key]: +e,
                    is_dropshipping: props.isDropShipping,
                });
            } else {
                setUserAddress({
                    ...userAddress,
                    [key]: e.target.value,
                    is_dropshipping: props.isDropShipping,
                });
            }
        }
    };

    return (
        <>
            <div className="order-address-cols">
                <div className="order-address-left">
                    <div className="input-block">
                        <input
                            autoFocus={true}
                            placeholder="Jméno a přijmení (název firmy)"
                            value={userAddress['company_name']}
                            onChange={(e) => handleChange(e, 'company_name')}
                            className={`input-block__field ${
                                invalid.includes('company_name') && 'is-invalid'
                            }`}
                            required=""
                        />
                    </div>
                    <div className="input-block">
                        <input
                            placeholder="IČO"
                            value={userAddress['ico']}
                            onChange={(e) => handleChange(e, 'ico')}
                            className={`input-block__field ${
                                invalid.includes('ico') && 'is-invalid'
                            }`}
                            required=""
                        />
                    </div>
                    <div className="input-block">
                        <input
                            placeholder="DIČ"
                            value={userAddress['dic']}
                            onChange={(e) => handleChange(e, 'dic')}
                            className={`input-block__field ${
                                invalid.includes('dic') && 'is-invalid'
                            }`}
                            required=""
                        />
                    </div>
                    <div className="input-block">
                        <input
                            placeholder="Ulice a číslo popisné"
                            value={userAddress['ulice']}
                            onChange={(e) => handleChange(e, 'ulice')}
                            className={`input-block__field ${
                                invalid.includes('ulice') && 'is-invalid'
                            }`}
                            required=""
                        />
                    </div>
                </div>
                <div className="order-address-right">
                    <div className="order-address-right__row">
                        <div className="input-block">
                            <SelectStat
                                value={userAddress['stat']}
                                onChange={(e) => handleChange(e, 'stat')}
                            />
                            {/*<input*/}
                            {/*    placeholder="Stat"*/}
                            {/*    value={userAddress['stat']}*/}
                            {/*    onChange={(e) => handleChange(e, 'stat')}*/}
                            {/*    className={`input-block__field ${*/}
                            {/*        invalid.includes('stat') && 'is-invalid'*/}
                            {/*    }`}*/}
                            {/*    required=""*/}
                            {/*/>*/}
                        </div>
                        <div className="input-block">
                            <input
                                placeholder="PSČ"
                                value={userAddress['psc']}
                                onChange={(e) => handleChange(e, 'psc')}
                                className={`input-block__field ${
                                    invalid.includes('psc') && 'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                    </div>
                    <div className="order-address-right__row">
                        <div className="input-block">
                            <input
                                placeholder="Město"
                                value={userAddress['mesto']}
                                onChange={(e) => handleChange(e, 'mesto')}
                                className={`input-block__field ${
                                    invalid.includes('mesto') && 'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                        <div className="input-block input-block--checkbox">
                            <label className="input-block__checkbox">
                                <input
                                    type="checkbox"
                                    className="input-block__checkbox-field"
                                    checked={
                                        userAddress['dic_check'] == 1
                                            ? true
                                            : null
                                    }
                                    onChange={() =>
                                        handleChange(
                                            userAddress['dic_check'] ? 0 : 1,
                                            'dic_check'
                                        )
                                    }
                                />
                                <span className="input-block__checkbox-check"></span>
                                <span className="input-block__checkbox-title">
                                    Nejsem plátcem DPH
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className="order-address-right__row">
                        <div className="input-block">
                            <input
                                placeholder="Telefon"
                                value={userAddress['telefon']}
                                onChange={(e) => handleChange(e, 'telefon')}
                                className={`input-block__field ${
                                    invalid.includes('telefon') && 'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                        <div className="input-block">
                            <input
                                placeholder="Kontaktní email"
                                value={userAddress['email']}
                                onChange={(e) => handleChange(e, 'email')}
                                className={`input-block__field ${
                                    invalid.includes('email') && 'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                    </div>
                    <div className="order-address-right__row">
                        <div className="input-block">
                            <input
                                placeholder="Předčíslí"
                                value={userAddress['bank_prefix']}
                                onChange={(e) => handleChange(e, 'bank_prefix')}
                                className={`input-block__field ${
                                    invalid.includes('bank_prefix') &&
                                    'is-invalid'
                                }`}
                            />
                        </div>
                        <div className="input-block">
                            <input
                                placeholder="Číslo účtu"
                                value={userAddress['bank_account_number']}
                                onChange={(e) =>
                                    handleChange(e, 'bank_account_number')
                                }
                                className={`input-block__field ${
                                    invalid.includes('bank_account_number') &&
                                    'is-invalid'
                                }`}
                            />
                        </div>
                        <div className="input-block">
                            <input
                                placeholder="Kód banky"
                                value={userAddress['bank_code']}
                                onChange={(e) => handleChange(e, 'bank_code')}
                                className={`input-block__field ${
                                    invalid.includes('bank_code') &&
                                    'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="order-address-button__wrap">
                <button
                    className="button button--solid order-address-button"
                    onClick={submit}
                    type="button"
                >
                    {global._e('Save address')}
                </button>
            </div>
        </>
    );
}
