import { combineReducers } from 'redux';
import authReducer from './auth';
import modalReducer from './modal';
import productReducer from './product';
import orderReducer from './order';
import staticReducer from './static';
import basicReducer from './basic';
import stepReducer from './step';
import profileReducer from './profile';
import otherReducer from './other';

const appReducer = combineReducers({
    basicReducer,
    staticReducer,
    orderReducer,
    productReducer,
    stepReducer,
    authReducer,
    modalReducer,
    profileReducer,
    otherReducer,
});

// const rootReducer = (state, action) => {
//     // when a logout action is dispatched it will reset redux state
//     // if (action.type === 'LOG_OUT') {
//     //     state = undefined;
//     // }
//
//     return appReducer(state, action);
// };

export default appReducer;
