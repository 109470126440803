import orderActions from './Order';
import productActions from './Product';
import stepActions from './Step';

const applyClearCart = (data = null) => {
    return {
        type: 'CLEAR_CART',
        payload: data,
    };
};

const clearCart = (step = 3) => {
    return async (dispatch) => {
        try {
            dispatch(applyClearCart());
            dispatch(orderActions.clearOrderState());
            dispatch(productActions.clearProductState());
            dispatch(stepActions.clearSteps());
        } catch (error) {
            global.Alert(error.toString());
            console.error(error);
        }
    };
};

export default { clearCart };
