import axios from 'axios';

axios.defaults.withCredentials = true;

export const updateClientAddress = async ( data ) => {
	let header = await global.getHeader();
	let res = await axios.post( `${global.API_ADDRESS}/api/updateClientAddress` , data , header ).catch( global.handleError );
	return res.data;
}


export const createClientAddress = async ( data ) => {
	let header = await global.getHeader();
	let res = await axios.post( `${global.API_ADDRESS}/api/createClientAddress` , data , header ).catch( global.handleError );
	return res.data;
}





export const validateClientAddress = ( data , isDropShipping = false ) => {
	let errors = [];
	if ( ! global.validateField( data[ 'client_name' ] , 5 ) ) {
		errors.push( 'client_name' );
	}
	if ( ! global.validateField( data[ 'ulice' ] , 3 ) ) {
		errors.push( 'ulice' );
	}
	if ( ! global.validateField( data[ 'stat' ] ) ) {
		errors.push( 'stat' );
	}
	if ( ! global.validateField( data[ 'mesto' ] , 3 ) ) {
		errors.push( 'mesto' );
	}
	if ( ! global.validateField( data[ 'psc' ] ) ) {
		errors.push( 'psc' );
	}
	if ( ! global.validatePhoneField( data[ 'telefon' ] ) ) {
		errors.push( 'telefon' );
	}
	if ( ! global.validateEmailField( data[ 'email' ] ) ) {
		errors.push( 'email' );
	}
	if ( isDropShipping === true ) {
		if ( ! global.validateField( data[ 'cash_on_delivery' ] ) ) {
			errors.push( 'cash_on_delivery' );
		}
	}
	if ( errors.length > 0 ) {
		return errors;
	}
	return true;
}