const initialState = {
    dropShipping: false,
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PROFILE_DROPSHIPPING_TRUE':
            return {
                ...state,
                dropShipping: true,
            };
        case 'SET_PROFILE_DROPSHIPPING_FALSE':
            return {
                ...state,
                dropShipping: false,
            };
        case 'TOGGLE_PROFILE_DROPSHIPPING':
            return {
                ...state,
                dropShipping: !state.dropShipping,
            };
        default:
            return state;
    }
};
export default profileReducer;
