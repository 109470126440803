import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FormHeader from '../components/FormHeader';
import FormFooter from '../components/FormFooter';
import { getAddressInfo } from '../actions/Address';
import { getProducts, createOrder } from '../actions/Product';
import WithAuth from '../hocs/WithAuth';
import Products from '../components/StepThree/Products';
import Summary from '../components/StepThree/Summary';
import Addresses from '../components/StepThree/Addresses';
import Comment from '../components/StepThree/Comment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import stepActions from '../redux/actions/Step';
import productActions from '../redux/actions/Product';
import modalActions from '../redux/actions/Modal';
import otherActions from '../redux/actions/Other';

function Screen(props) {
    const {
        orderProducts,
        actions,
        stepThree,
        stepTwo,
        stepOne,
        deliveryPrice,
        finalData,
    } = props;
    let history = useHistory();
    const [canView, setCanView] = useState(false);
    const [showingImages, setShowingImages] = useState(false);
    const [canContinue, setCanContinue] = useState(false);
    const [isDropShipping, setIsDropShipping] = useState(false);
    const [userAddress, setUserAddress] = useState({});
    const [products, setProducts] = useState({});
    const [clientAddress, setClientAddress] = useState({});
    const [stepOneInfo, setStepOneInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [creatingOrder, setCreatingOrder] = useState(false);
    const [comment, setComment] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceVat, setTotalPriceVat] = useState(0);
    const [totalProductCount, setTotalProductCount] = useState(0);

    const fetchData = async () => {
        let canView = await actions.canStepThree();
        if (!canView) {
            history.push('step_2');
            return false;
        }
        setCanView(canView);
        setCanContinue(canView);
        if (stepTwo?.clientAddress) {
            setClientAddress(stepTwo?.clientAddress);
        }
        let productsInfo = orderProducts;
        if (!productsInfo || Object.keys(productsInfo).length < 1) {
            setLoading(true);
            // global.Alert(_e('Cart is empty'));
            setTimeout(() => {
                history.push('/orders');
                return false;
            }, 1500);
            return false;
        }
        let comment = stepThree?.comment ?? '';
        setComment(comment);
        let addressInfo = await getAddressInfo(stepOne, stepTwo);
        let products = await getProducts(productsInfo);
        console.log(products, Object.values(products));
        setProducts(Object.values(products));
        let dropShipping = stepOne?.dropShipping;
        setIsDropShipping(dropShipping);
        const userAddress = addressInfo['userAddress'];
        const clientAddress = addressInfo['clientAddress'];
        setStepOneInfo({
            deliveryMethod: addressInfo['deliveryMethod'],
            paymentMethod: addressInfo['paymentMethod'],
        });
        if (null !== clientAddress) {
            setClientAddress(clientAddress);
        }
        setUserAddress(userAddress);
        setLoading(false);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        const { showOrders } = actions;
        showOrders();

        fetchData();

        document.title = global._e('Souhrn');
    }, []);
    useEffect(() => {
        fetchData();
    }, [orderProducts]);

    useEffect(() => {
        if (Object.keys(products).length > 0) {
            let total = products.reduce(
                (sum, product) => sum + product.price * product.count,
                0
            );
            let totalVat = products.reduce(
                (sum, product) => sum + product.price_vat * product.count,
                0
            );
            let totalCount = products.reduce(
                (sum, product) => sum + product.count,
                0
            );
            setTotalPrice(total);
            setTotalPriceVat(totalVat);
            setTotalProductCount(totalCount);
        }
    }, [products]);
    const commentHandler = (e) => {
        setComment(e.target.value);
        actions.setStepThree({ comment: e.target.value });
    };
    const preSubmit = async () => {
        actions.setConfirm({
            text: global._e('Chcete odeslat objednávku?'),
            callback: async () => {
                setCreatingOrder(true);
                setLoading(true);
                await submit();
                setLoading(false);
                setCreatingOrder(false);
                actions.setConfirm('');
            },
        });
        return false;
        let res = window.confirm('Chcete odeslat objednávku?');
        if (res) {
            setCreatingOrder(true);
            setLoading(true);
            await submit();
            setLoading(false);
            setCreatingOrder(false);
        } else {
            return false;
        }
    };
    const toggleShowingImages = () => {
        setShowingImages(!showingImages);
    };
    const submit = async () => {
        const data = { stepOne, stepTwo, stepThree, orderProducts };
        if (null !== stepThree) {
            data['stepThree']['delivery_price'] = deliveryPrice;
        } else {
            data['stepThree'] = { delivery_price: deliveryPrice };
        }
        let resp = await createOrder(data);
        // let resp = true;
        if (resp && (resp > 1 || resp == true)) {
            await actions.setConfirm('');
            actions.setMessage(
                global._e(`Děkujeme za Vaši objednávku`) + ` № ${resp}`,
                false
            );
            setTimeout(async () => {
                actions.clearCart();
            }, 2500);
            // global.clearStorage();
        } else {
            actions.setErrorMessage(
                global._e(`There was an error while creating order`)
            );
        }
    };

    // CLIENT ADDRESS
    return (
        <div className="order">
            <FormHeader
                canContinue={canContinue}
                loading={loading}
                step="3"
                title={_e('Souhrn')}
            />
            {loading && creatingOrder ? (
                <h1 className="order-cart-title-creating-order">
                    {global._e('Please wait')}
                </h1>
            ) : null}
            {canView && !loading && (
                <div className="order-content-3">
                    <div className="container">
                        <div className="order-cart">
                            <div className="order-cart-top">
                                <h1 className="order-cart-title">
                                    {global._e('Products')}
                                </h1>
                                <div className="order-cart-view-all-products__wrap">
                                    <div
                                        onClick={toggleShowingImages}
                                        className="order-cart-view-all-products closed"
                                    >
                                        {showingImages
                                            ? global._e(
                                                  'Скрыть картинки товаров'
                                              )
                                            : global._e(
                                                  'Показать картинки товаров'
                                              )}
                                    </div>
                                </div>
                            </div>
                            <Products showingImages={showingImages} />
                            <Summary
                                paymentMethod={stepOneInfo.paymentMethod}
                                dropShipping={isDropShipping}
                                deliveryMethod={stepOneInfo.deliveryMethod}
                                totalPrice={totalPrice}
                                totalPriceVat={totalPriceVat}
                                productCount={totalProductCount}
                            />
                            <Addresses
                                userAddress={userAddress}
                                clientAddress={clientAddress}
                            />
                            <Comment
                                submit={preSubmit}
                                value={comment}
                                onChange={(e) => commentHandler(e)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
const mapStateToProps = (state) => ({
    stepOne: state.stepReducer.stepOne,
    stepTwo: state.stepReducer.stepTwo,
    stepThree: state.stepReducer.stepThree,
    orderProducts: state.orderReducer.products,
    finalData: state.stepReducer,
    deliveryPrice: state.orderReducer.deliveryPrice,
});

const ActionCreators = Object.assign(
    {},
    productActions,
    stepActions,
    modalActions,
    otherActions
);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithAuth(Screen));
