import React, { useEffect, useState } from 'react';
import productActions from '../../redux/actions/Product';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
let timer = null;

function Screen(props) {
    const {
        toggleOrders,
        filterProducts,
        menuToggleAllProducts,
        setProductsFilterQuery,
    } = props.actions;
    const {
        cartFull,
        productsFilterQuery,
        ordersShown,
        menuAllProductsShown,
    } = props;
    const [inputVal, setInputVal] = useState('');
    const showOrderedProducts = () => {
        toggleOrders();
    };
    const makeFilterProducts = (e) => {
        const value = e.target.value;
        setInputVal(value);
        // setProductsFilterQueryLocal(value);
        clearTimeout(timer);
        timer = setTimeout(() => {
            setProductsFilterQuery(value);
            filterProducts(value);
        }, 1000);
    };
    useEffect(() => {
        setInputVal(productsFilterQuery);
    }, [productsFilterQuery]);
    useEffect(() => {
        return () => {
            clearTimeout(timer);
        };
    }, []);
    return (
        <div className="products-header">
            <div className="products-header__left">
                <h1 className="products-header__title">{_e('Products')}</h1>
            </div>
            <div className="products-header__right">
                <a
                    onClick={menuToggleAllProducts}
                    className="products-header__link products-header__view-all-products closed pointer"
                >
                    {menuAllProductsShown
                        ? _e('Menu hide all products')
                        : _e('Menu show all products')}
                </a>
                {cartFull ? (
                    <a
                        onClick={() => showOrderedProducts()}
                        className="products-header__link products-header__view-order-products closed pointer"
                    >
                        {ordersShown
                            ? _e('Show ordered products')
                            : _e('Show all products')}
                    </a>
                ) : null}
                <div className="search products-header__search">
                    <input
                        type="text"
                        className="search__input"
                        placeholder={_e('Search products')}
                        onChange={makeFilterProducts}
                        value={inputVal}
                    />
                    <span className="search__icon">
                        <img
                            src="../assets/img/icons/search.png"
                            alt="search"
                        />
                    </span>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cats: state.productReducer.cats,
    loading: state.productReducer.loading,
    cartFull: state.orderReducer.cartFull,
    ordersShown: state.productReducer.ordersShown,
    menuAllProductsShown: state.productReducer.menuAllProductsShown,
    productsFilterQuery: state.productReducer.productsFilterQuery,
});

const ActionCreators = Object.assign({}, productActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
