import React, { useEffect, useState } from 'react';

const Select = ({
    className,
    caption,
    options,
    onChange,
    initialVal,
    value,
}) => {
    const [selectedVal, setSelectedVal] = useState(null);
    const select = (selectedObject) => {
        const assigned = Object.assign({}, selectedObject);
        if (onChange) {
            onChange(assigned);
        }
        setSelectedVal(assigned);
        // alert('selecting object' + assigned?.id);
    };
    useEffect(() => {
        // console.log({ value });
        if (value?.id !== selectedVal?.id) {
            select(value);
        }
        // if (options && options.length) {
        //     setTimeout(() => {
        //         console.log('selectiong');
        //         select(options[0]);
        //     }, 1200);
        // }
    }, [value]);
    // className order-delivery__select
    return (
        <div className={`select ${className || ''}`}>
            {/*<input className="select__value" type="hidden" value="" />*/}
            <div className="select__caption">{caption}</div>
            <div className="select__container">
                <div className="select__curr closed">{value?.title}</div>
                <ul className="select__list">
                    {options.map((el, key) => {
                        return (
                            <li
                                key={key}
                                className="select__item"
                                onClick={() => select(el)}
                                // data-value={}
                            >
                                {el.title}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Select;
