import React, { useState, useEffect } from 'react';
import Address from '../components/Address';
import AddressForm from '../components/AddressForm';
import ClientAddressForm from '../components/ClientAddressForm';
import ClientAddress from '../components/ClientAddress';
import Loader from '../components/Loader';
import WithAddresses from '../hocs/WithAddresses';
import WithAuth from '../hocs/WithAuth';
import { connect } from 'react-redux';
import { OrderList } from './Orders';
import { getOrders } from '../actions/Order';
const fuckingContacts = [
    {
        name: 'Марианна Казаченко',
        fuck_position: 'технический отдел',
        fuck_phone: '+789 90 320-99-99',
        fuck_mail: 'kazachka@gmail.com',
    },
    {
        name: 'Марианна Казаченко',
        fuck_position: 'коммерческий отдел',
        fuck_phone: '+789 90 320-99-99',
        fuck_mail: 'kazachka@gmail.com',
    },
    {
        name: 'Марианна Казаченко',
        fuck_position: 'коммерческий отдел',
        fuck_phone: '+789 90 320-99-99',
        fuck_mail: 'kazachka@gmail.com',
    },
];

const ContactList = ({ contacts }) => {
    return (
        <div className="contacts-wrapper">
            {contacts.map((contact, key) => {
                return (
                    <div className="contact-wrapper" key={`fuck-${key}`}>
                        <img
                            className="contact-img"
                            src="/assets/img/girl.png"
                            alt=""
                        />
                        <div className="contact-div">
                            <p className="contact-name">{contact.name}</p>
                            <p className="contact-position">
                                {contact.fuck_position}
                            </p>
                            <p className="contact-phone">
                                {contact.fuck_phone}
                            </p>
                            <a
                                target="_blank"
                                href={`mailto:${contact.fuck_mail}`}
                                className="contact-mail"
                            >
                                {contact.fuck_mail}
                            </a>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
function Screen(props) {
    const [orders, setOrders] = useState([]);
    const { profileDropShipping, actions, user } = props;
    const [isDropShipping, setIsDropShipping] = useState(profileDropShipping);
    const toggleIsDropshipping = () => {
        actions.toggleProfileDropShipping();
        setIsDropShipping(!isDropShipping);
    };
    const logout = async () => {
        await actions.logOut();
        location.reload();
    };
    async function fetchData() {
        let ordersFetched = await getOrders(orders.length);
        // if (ordersFetched.isLast != undefined) setIsLast(true);
        if (undefined !== ordersFetched.orders) {
            setOrders([...orders, ...ordersFetched.orders.slice(0, 4)]);
        }
    }
    useEffect(() => {
        fetchData();
        document.title = global._e('My profile');
    }, []);
    return (
        <div>
            <main className="main container">
                {props.canView && (
                    <div>
                        <h1 className="my-5 text-center user-story-header">
                            {global._e('My addresses')}
                        </h1>
                        {/*<div className="profileAddressesDropshippingWrapper">*/}
                        {/*    <span className="profileAddressesDropshippingLabel">*/}
                        {/*        {global._e('Dropshipping addresses')}*/}
                        {/*    </span>*/}
                        {/*    <label className="order-info-block__checkbox">*/}
                        {/*        <input*/}
                        {/*            checked={isDropShipping}*/}
                        {/*            onChange={toggleIsDropshipping}*/}
                        {/*            type="checkbox"*/}
                        {/*            className="order-info-block__checkbox-input"*/}
                        {/*        />*/}
                        {/*        <span className="order-info-block__checkbox-check"></span>*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        {/*<hr />*/}
                        <h2 className="my-5 text-gray">
                            {global._e('Fakturační údaje')}
                        </h2>
                        {props.loading ? (
                            <Loader />
                        ) : (
                            <>
                                <div className="row d-flex mb-4">
                                    {props.userAddresses.map((val, key) => {
                                        return (
                                            <Address
                                                isForProfile={true}
                                                selectedAddress={
                                                    props.selectedAddress
                                                }
                                                onSelect={props.selectAddress}
                                                onEdit={props.onEditAddress}
                                                key={key}
                                                data={val}
                                            />
                                        );
                                    })}
                                    {props?.userAddresses?.length >=
                                    1 ? null : (
                                        <Address
                                            isForProfile={true}
                                            onAddPress={props.onAddPress}
                                            empty={true}
                                        />
                                    )}
                                </div>
                                {props.addingUserAddress && (
                                    <AddressForm
                                        action="add"
                                        onSubmit={props.onDoneAdd}
                                        isDropShipping={props.isDropShipping}
                                    />
                                )}
                                {props.editingUserAddress && (
                                    <AddressForm
                                        action="edit"
                                        onSubmit={props.onDoneEdit}
                                        userAddressEdit={props.userAddressEdit}
                                    />
                                )}
                                <h2 className="my-5 mt-6 text-gray">
                                    {global._e('Dodací údaje')}
                                </h2>
                                <div className="row d-flex mb-4">
                                    {props.clientAddresses.map((val, key) => {
                                        return (
                                            <ClientAddress
                                                isForProfile={true}
                                                selectedAddress={
                                                    props.selectedClientAddress
                                                }
                                                onSelect={props.selectAddress}
                                                onEdit={
                                                    props.onEditClientAddress
                                                }
                                                key={key}
                                                data={val}
                                            />
                                        );
                                    })}
                                    <ClientAddress
                                        isForProfile={true}
                                        onAddPress={props.onAddClientPress}
                                        empty={true}
                                    />
                                </div>
                                {props.addingClient && (
                                    <ClientAddressForm
                                        action="add"
                                        onValidated={false}
                                        onSubmit={props.onDoneAddClient}
                                    />
                                )}
                                {props.editingClient && (
                                    <ClientAddressForm
                                        action="edit"
                                        onValidated={false}
                                        onSubmit={props.onDoneEditClient}
                                        clientAddress={props.clientAddress}
                                    />
                                )}
                                <div className="mt-6">
                                    <h2 className="my-5 mt-6 text-gray">
                                        {global._e('Последние заказы')}
                                    </h2>
                                    {orders?.length ? (
                                        <OrderList
                                            orders={orders}
                                            user={user}
                                        />
                                    ) : null}
                                </div>
                                <div className="mt-6">
                                    <h2 className="my-5 mt-6 text-gray">
                                        {global._e('Our contacts')}
                                    </h2>
                                    {fuckingContacts?.length ? (
                                        <ContactList
                                            contacts={fuckingContacts}
                                        />
                                    ) : null}
                                </div>
                            </>
                        )}
                    </div>
                )}
                <button
                    onClick={logout}
                    className="button button--solid order-action__submit my-4  text-center btn-danger btn-block"
                >
                    {_e('Log out')}
                </button>
            </main>
        </div>
    );
}

const mapStateToProps = (state) => ({
    profileDropShipping: state.profileReducer.dropShipping,
    user: state.authReducer.user,
});

export default connect(
    mapStateToProps,
    null
)(WithAddresses(WithAuth(Screen), false));
