import React from 'react';
import { connect } from 'react-redux';

const Screen = (props) => {
    const { productsFilterQuery } = props;
    return productsFilterQuery.length ? (
        <h1>{_e('Nothing found')}</h1>
    ) : (
        <h1>{_e('Please Select Category')}</h1>
    );
};

const mapStateToProps = (state) => ({
    ordersShown: state.productReducer.ordersShown,
    menuAllProductsShown: state.productReducer.menuAllProductsShown,
    productsFilterQuery: state.productReducer.productsFilterQuery,
});

export default connect(mapStateToProps, null)(Screen);
