import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { handleUserCheckedTerms } from '../../redux/actions/Auth';
import { toggleTermsModalAction } from '../../redux/actions/Modal';

function Component(props) {
    const { onChange, value, dispatch } = props;
    const [checked, setChecked] = useState(false);
    const submit = () => {
        if (checked) {
            return props.submit();
        } else {
            global.Alert(global._e('Please accept the terms'));
        }
    };
    const onChecked = async (e) => {
        if (e.target.checked) {
            const res = await dispatch(handleUserCheckedTerms());
            if (res === true) {
                setChecked(e.target.checked);
            }
        } else {
            setChecked(false);
        }
    };
    return (
        <div className="order-action">
            <form className="order-action__form">
                <div className="order-action__field-block">
                    <label
                        htmlFor="order-comment"
                        className="order-action__label"
                    >
                        {_e('Comment')}
                    </label>
                    <textarea
                        onChange={onChange}
                        id="order-comment"
                        className="order-action__comment"
                        value={value}
                    ></textarea>
                </div>
                <div className="auth-form-checkbox auth-form-personal checkbox-stepthree">
                    <div className="radio radio-custom">
                        <label className="radio__label">
                            <input
                                name="agree"
                                className="radio__input"
                                type="checkbox"
                                onChange={onChecked}
                            />
                            <span className="radio__check"></span>
                            <span className="radio__title">{_e('Agree')}</span>
                        </label>
                    </div>
                    <a
                        onClick={() => dispatch(toggleTermsModalAction())}
                        className="auth-form-personal__link cursor pointer"
                    >
                        {_e('условия пользовательского договора')}
                    </a>
                </div>
                <div className="order-action__buttons">
                    {/*<button className="button order-action__dop-order">*/}
                    {/*    Дозаказать*/}
                    {/*</button>*/}
                    <div
                        onClick={submit}
                        className="cursor pointer button button--solid order-action__submit"
                    >
                        {_e('Pokrakovat')}
                    </div>
                </div>
            </form>
        </div>
    );
}

export default connect()(Component);
