import React, { useState, useEffect } from 'react';
import {
    Link,
    // useHistory
} from 'react-router-dom';
import { getOrders, getOrder } from '../actions/Order';
import Loader from '../components/Loader';
// let offset = 0;
import WithAuth from '../hocs/WithAuth';
import ProductView from '../components/Home/ProductView';
import ProductCats from '../components/Home/ProductCats';
import ProductFooter from '../components/Home/Footer';
import ProductHeader from '../components/Home/ProductHeader';
import ProductMobile from '../components/Home/ProductHeaderMobile';
import productActions from '../redux/actions/Product';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function Screen(props) {
    const { products, cats } = props;
    const { getProducts } = props.actions;
    // let history = useHistory();
    const [orders, setOrders] = useState([]);
    const [isLast, setIsLast] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getProducts();
        setLoading(false);
        document.title = global._e('Velkoobchod s dětským zbožím');
    }, []);
    // CLIENT ADDRESS
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <main className="main">
                    <div className="products">
                        <div className="container products-main-container">
                            <ProductMobile />
                            <ProductHeader />
                            <div className="products-body">
                                <ProductCats />
                                <ProductView />
                            </div>
                        </div>
                        <ProductFooter />
                    </div>
                </main>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    products: state.productReducer.products,
    cats: state.productReducer.cats,
    loading: state.productReducer.loading,
});

const ActionCreators = Object.assign({}, productActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithAuth(Screen));
