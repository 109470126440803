import axios from 'axios';

axios.defaults.withCredentials = true;

export const getOrders = async (offset) => {
    let header = await global.getHeader();
    let res = await axios
        .get(`${global.API_ADDRESS}/api/getOrders/${offset}`, header)
        .catch(global.handleError);
    return res?.data;
};

export const getOrder = async (id) => {
    let header = await global.getHeader();
    let res = await axios
        .get(`${global.API_ADDRESS}/api/getOrder/${id}`, header)
        .catch(global.handleError);
    return res?.data;
};

// export const createOrder = async ( data ) => {
// 	let header = await global.getHeader();
// 	let res = await axios.post( `${global.API_ADDRESS}/api/createOrder` , data , header ).catch( global.handleError );
// 	return res.data;
// }
