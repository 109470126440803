const initialState = {
    loading: false,
    total: 0,
    products: [],
    cats: [],
    error: false,
    ordersShown: false,
    menuAllProductsShown: false,
    productsFilterQuery: '',
    selectedCat: 0,
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'MENU/HIDE_ALL_PRODUCTS':
            return {
                ...state,
                menuAllProductsShown: false,
            };
        case 'MENU/SHOW_ALL_PRODUCTS':
            return {
                ...state,
                menuAllProductsShown: true,
            };
        case 'MENU/TOGGLE_ALL_PRODUCTS':
            return {
                ...state,
                menuAllProductsShown: !state.menuAllProductsShown,
            };
        case 'TOGGLE_ORDERS':
            return {
                ...state,
                ordersShown: !state.ordersShown,
            };
        case 'HIDE_ORDERS':
            return {
                ...state,
                ordersShown: false,
            };
        case 'SHOW_ORDERS':
            return {
                ...state,
                ordersShown: true,
            };
        case 'SET_PRODUCT_FILTER_QUERY':
            return {
                ...state,
                productsFilterQuery: action.payload,
            };
        case 'CLEAR_PRODUCT_STATE':
            return {
                ...state,
                total: 0,
                error: false,
                ordersShown: false,
                menuAllProductsShown: false,
                productsFilterQuery: '',
            };
        case 'GET_PRODUCTS':
            return {
                ...state,
                products: action.payload.products,
                cats: action.payload.cats,
                total: action.payload.total ?? state.total,
                loading: action.payload.loading ?? false,
                productsFilterQuery:
                    action.payload.productsFilterQuery ??
                    state.productsFilterQuery,
                selectedCat: action.payload.selectedCat ?? state.selectedCat,
            };
        default:
            return state;
    }
};
export default productReducer;
