const initialState = {};

const basicReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_BASIC':
            return action.payload;
        default:
            return state;
    }
};
export default basicReducer;
